import {Link} from "react-router-dom";
import * as React from 'react';
import { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Anchor } from "./Anchor";

import { 
  Typography, 
  Container, 
  List,
  Button,
  Box,
  Fade,
  ListItem, 
  ListItemIcon, 
  Slide,
  Grid
 } from '@mui/material';
 import { 
  CheckCircleOutline,
  EnhancedEncryption,
  VpnKey,
  Terminal,
  Lan

} from '@mui/icons-material';
import wallpaper from './images/asabg.png'
import cyber12 from './images/cyber12.png'
import cyber14 from './images/cyber14.5.png'
import cyber17 from './images/cyber17.png'
import './App.css';



export function Network() {

  const theme = useTheme()

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

    return (
      <div>
      <Container maxWidth={false} style={{backgroundImage: `url(${wallpaper})`,backgroundSize: "cover", alignItems: "center", 
      minHeight: "400px", display: 'flex'}} sx={{p: 5}} align="center"  >
        <Slide direction='right' unmountOnExit={true}
          {...{timeout: {enter:1000, exit:1000} }} in={true}> 
          <Container  maxWidth='md' sx={{borderRadius: 2, p: 4}}>
              <Typography component={"div"} align='left' variant='title' color={theme.palette.primary.lightest}  fontWeight='900' > 
                Wireless and Network Security
              </Typography>           
          </Container>
        </Slide>
      </Container>

        <Container maxWidth='false' sx={{padding: {
          xs: '12px', // Padding for extra small screens
          sm: '15px', // Padding for small screens
          md: '20px', // Padding for medium screens
          lg: '60px'}}}>
        <Fade unmountOnExit={true} style={{ transformOrigin: '3 3 3', transitionDelay: '0ms' }}
              {...{timeout: {enter:1000, exit:1000} }} in={true}> 
            <Container maxWidth="xl"  style={{ height: "auto"}}  align="center"  >
              <Grid  container direction="row"alignItems="center" spacing={10}>
                
                <Grid item md={6} align="left">
                <Typography component={"div"}variant="h1"  color={'primary'} fontWeight={800}>
                Wireless networks are everywhere...and so are hackers
                </Typography><br></br>
                  <Typography component={"div"} color={'primary'}  > 
                  Wireless networks are ubiquitous in today's interconnected world, seamlessly facilitating 
                  communication and access across devices and locations. However, their pervasive presence also 
                  attracts malicious actors keen on exploiting vulnerabilities. With the convenience of wireless 
                  connections comes the heightened risk of unauthorized access, data interception, and cyberattacks. 
                  Hackers can exploit weak security protocols, unsecured devices, or misconfigured networks to gain entry 
                  and compromise sensitive information. Vigilance and robust security measures, including encryption, 
                  strong passwords, and regular updates, are crucial to mitigate these risks and ensure the safety of 
                  wireless network environments in both personal and organizational settings. ASA specializes in 
                  implementing comprehensive strategies to safeguard networks against evolving threats, offering expertise 
                  and solutions that help maintain the integrity and security of wireless infrastructures.
                  </Typography><br></br>
                 
                  <br></br>
                </Grid>
                <Grid item md={6} >
                 <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
                    <img style={{ width: '600px', height: '470px' }} src={cyber17} alt="alternative_text"></img>
                  </Box>
                  <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <img style={{ width: '300px', height: '200px' }}src={cyber17} alt="alternative_text"></img>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Fade>
        </Container>

        <Container maxWidth={false} style={{height: "auto"}} sx={{padding: {
          xs: '15px', // Padding for extra small screens
          sm: '15px', // Padding for small screens
          md: '20px', // Padding for medium screens
          lg: '40px'}, background: theme.palette.primary.gradient}} >
        <Fade unmountOnExit={true} style={{ transformOrigin: '3 3 3', transitionDelay: '0ms' }}
              {...{timeout: {enter:1000, exit:1000} }} in={true}> 
        <Grid  container direction="row-reverse" justifyContent="center" alignItems="center" spacing={1}>
          <Grid item md={5}   align="left">
          <Typography component={"div"}variant="h3"  color={'secondary'} fontWeight={800}>
          Why Does Your Business Need Wireless Network Assessment Services?
          </Typography><br></br>
          <Typography component={"div"}variant="h1"  color={'primary'} fontWeight={800}>
          Using wireless networks can present risks.
          </Typography><br></br>
          <Typography component={"div"} color={'primary'} >
          It can allow your physical security controls to be circumvented, and access can be gained to your systems. 
          By using WiFi Security Assessment Services provided by ASA, any dangers (or potential dangers) will 
          be highlighted to you. Here are some benefits of using Wireless Security Assessment Services:
          </Typography>
          <br></br>
          <List>
            <ListItem sx={{pl: 0, alignItems: 'flex-start'}}>
              <ListItemIcon >
                <CheckCircleOutline style={{ fontSize: '30px' }} color="secondary"  />
              </ListItemIcon>
              <div>
                <Typography component={"div"}color='primary'  >
                  Your business can be confident that WiFi access is safe and secure. Plus, you can 
                  be assured that internal and user services are appropriately protected.
                </Typography>
              </div>
            </ListItem>
            <ListItem sx={{pl: 0, alignItems: 'flex-start'}}>
              <ListItemIcon >
                <CheckCircleOutline style={{ fontSize: '30px' }} color="secondary"  />
              </ListItemIcon>
              <div>
                <Typography component={"div"}color='primary'  >
                It can enhance your security processes. This is achieved by confirming that 
                your wireless networks meet the requirements of data protection and security compliance.
                </Typography>
              </div>
            </ListItem>
            <ListItem sx={{pl: 0, alignItems: 'flex-start'}} >
              <ListItemIcon >
                <CheckCircleOutline style={{ fontSize: '30px' }} color="secondary"  />
              </ListItemIcon>
              <div>
                <Typography component={"div"}color='primary'  >
                It will lessen the risk of loss. These services stop malicious attackers from 
                penetrating your network and compromising important internal company assets.
                </Typography>
              </div>
            </ListItem>
          </List>
            <br></br>
          </Grid>
          <Grid item md={6}>
            <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
                <img style={{ width: '600px', height: '700px' }} src={cyber14} alt="alternative_text"></img>
              </Box>
              <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <img style={{ width: '290px', height: '350px' }}src={cyber14} alt="alternative_text"></img>
              </Box>
          </Grid>
        </Grid>
        </Fade>
      </Container>

      <Container maxWidth={false} style={{height: "auto"}} sx={{padding: {
          xs: '12px', // Padding for extra small screens
          sm: '15px', // Padding for small screens
          md: '20px', // Padding for medium screens
          lg: '70px'}}} >
        <Grid  container direction="row" justifyContent="center" alignItems="center" spacing={1}>
          <Grid item md={6}   align="left">
          <Typography component={"div"}variant="h1"  color={'primary'} fontWeight={800}>
              Among Other Things, We'll Verify 
          </Typography><br></br>
            <List>
                <ListItem sx={{pl: 0, alignItems: 'flex-start'}}>
                  <ListItemIcon >
                    <EnhancedEncryption style={{ fontSize: '30px' }} color="secondary"  />
                  </ListItemIcon>
                  <div>
                    <Typography component={"div"}color='primary' variant="h3" fontWeight={900}>Appropriate Encryption Algorithm Usage
                    </Typography>
                    <Typography component={"div"}color='primary' variant="body">
                      Everyday hackers are finding ways to break encryption once thought impossible. We'll make sure you're using the strongest
                      encryption available.       
                    </Typography>
                  </div>
                </ListItem>

                <ListItem sx={{pl: 0, alignItems: 'flex-start'}}>
                  <ListItemIcon >
                    <VpnKey style={{ fontSize: '30px' }} color="secondary"  />
                  </ListItemIcon>
                  <div>
                    <Typography component={"div"}color='primary' variant="h3" fontWeight={900}>Access Controls
                    </Typography>
                    <Typography component={"div"}color='primary' variant="body"> 
                    Proper access controls are crucial to maintaining a secure wireless network. Putting the 
                    right protocols in place to align your business with industry standards is paramount.
                    </Typography>
                  </div>
                </ListItem>

                <ListItem sx={{pl: 0, alignItems: 'flex-start'}}>
                  <ListItemIcon >
                    <Lan style={{ fontSize: '30px' }} color="secondary"  />
                  </ListItemIcon>
                  <div>
                    <Typography component={"div"}color='primary' variant="h3" fontWeight={900}>Network Isolation
                    </Typography>
                    <Typography component={"div"}color='primary' variant="body">
                      Logical network segmentation can be the difference between a nice day at the office and an 
                      irreparable catastrophy. We'll make sure your network backdoors are closed to the wrong people.
                    </Typography>
                  </div>
                </ListItem>

                <ListItem sx={{pl: 0, alignItems: 'flex-start'}}>
                  <ListItemIcon >
                    <Terminal style={{ fontSize: '30px' }} color="secondary"  />
                  </ListItemIcon>
                  <div>
                    <Typography component={"div"}color='primary' variant="h3" fontWeight={900}>Proper Firmware and Software Installation</Typography>
                    <Typography component={"div"}color='primary' variant="body">
                      Manufacturers are frequently pushing software and firmware patches due to discovered vulnerabilities. Our job
                      is to make sure these vulnerabilites don't exist on your systems.
                    </Typography>
                  </div>
                </ListItem>
              </List>
                <br></br>
                <Link to = "/contact" >
                      <Button color="secondary" sx={{width: 180, height: 40, textTransform: 'none', borderRadius: 2 }} variant='contained'>
                          <Typography component={"div"} color={theme.palette.primary.lightest} fontWeight={600} > Talk to an Expert</Typography>
                      </Button>
                    </Link>
              <br></br>
            </Grid>
            <Grid item md={4}>
              <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
                  <img style={{ width: '600px', height: '500px' }} src={cyber12} alt="alternative_text"></img>
                </Box>
                <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                  <img style={{ width: '350px', height: '290px' }}src={cyber12} alt="alternative_text"></img>
                </Box>
            </Grid>
          </Grid>
          <br></br><br></br>
        </Container>
        <Anchor/>
      </div>
      )}