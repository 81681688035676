import React, { useState, useEffect } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"
import {Link} from "react-router-dom";
import { 
  Typography, 
  Container, 
  Button,
  Box,
  Fade,
  List,
  ListItem, 
  ListItemIcon, 
  Grid
 } from '@mui/material';
 
import {
  CheckCircleOutline
  } from '@mui/icons-material';
import cyber2 from './images/cyber2.png'
import cyber3 from './images/cyber3.png'
import cyber4 from './images/cyber4.png'
import cyber5 from './images/cyber5.png'
import cyber6 from './images/cyber6.png'
import cyber7 from './images/cyber7.png'
import cyber8 from './images/cyber8.png'
import wallpaper from './images/people.jpg'
import { useTheme } from '@mui/material/styles';
import { Anchor } from "./Anchor";
import { TypeAnimation } from 'react-type-animation';
import './App.css';

export function Services() {

  const theme = useTheme()

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  const [isExpanded, setIsExpanded] = useState(true);
  
  
  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  }

  return (
    <div >
        <Container  maxWidth={false} style={{backgroundImage: `url(${wallpaper})`,backgroundSize: "cover", alignItems: "center", 
        minHeight: "400px", display: 'flex'}} sx={{p: 5}} align="center"  >
          <Fade unmountOnExit={true} style={{ transformOrigin: '3 3 3', transitionDelay: '250ms' }}
          {...{timeout: {enter:1500, exit:1000}}}  in={true}>
            <Container  maxWidth='sm' sx={{borderRadius: 2, p: 4}} style={{backgroundColor: theme.palette.primary.trans}}>
              <Typography component={"div"} variant='title' color={theme.palette.primary.lightest}  fontWeight='900' >
               Our Services
              </Typography>           
            </Container>
          </Fade>
        </Container>

        <Container sx={{padding: {
          xs: '25px', // Padding for extra small screens
          lg: '70px'} }} maxWidth="false" style={{ height: "auto"}} align="center"  >
            <Container maxWidth="xl" style={{height: "auto"}} sx={{ p: 1}} align="center"  >
              <Grid  container direction="row" justifyContent="center" alignItems="center" spacing={10}>
                <Grid item xl={10} align="left">
                  <Typography component={"div"}variant="h1"  color={'primary'} fontWeight={900}>
                    <TypeAnimation
                      sequence={[
                        500,
                        'Trust Us to Secure Your Processes',
                        800,
                        'Trust Us to Secure Your People',
                        800,
                        'Trust Us to Secure Your Technology',
                        800,
                        'Trust Us to Secure Your Business',
                        800
                        ]}
                        wrapper="div"
                        speed={50}
                        // style={{ fontSize: theme.typography.title.fontSize, color: theme.palette.primary.lightest }}
                      >
                    </TypeAnimation>
                  </Typography>
                    <br></br>
                  <Box >
                  <Fade unmountOnExit={true} style={{ transformOrigin: '3 3 3' }}
                    {...{timeout: {enter:2000, exit:1000}}}  in={true}>
                  
                  <Typography component={"div"}variant='h2'  color={'primary'}> 
                  IT security goes beyond just technical controls. An effective security posture consists of a well-trained workforce, 
                  robust security controls, and a strong plan in place if disaster strikes. Our comprehensive service 
                  offerings will provide you the peace of mind you need to focus your efforts on what matters most: your business and it's customers.
                  </Typography>
                  </Fade>
                  </Box>
                </Grid>
               
              </Grid>
            </Container>
        </Container>

        <Container sx={{padding: {
          xs: '10px', // Padding for extra small screens
          lg: '30px'}, background: theme.palette.primary.gradient}} maxWidth="false" style={{ height: "auto"}} align="center"  >
          <Container maxWidth="xl" style={{ height: "auto"}} align="center"  >
            <Grid  /*backgroundColor={(theme) => theme.palette.primary.light}*/ container direction="row-reverse" justifyContent="center" alignItems="center" spacing={10}>
              <Grid item lg={5}>
              <ScrollAnimation animateOnce animateIn="slideInRight">
                <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
                  <img style={{ width: '600px', height: '500px' }} src={cyber5} alt="alternative_text"></img>
                </Box>
                <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                  <img style={{ width: '350px', height: '290px' }} src={cyber5} alt="alternative_text"></img>
                </Box>
              </ScrollAnimation>
              </Grid>
              <Grid item lg={6} align="left">
              <ScrollAnimation animateOnce animateIn="slideInLeft">
              <Typography component={"div"}variant="h1"  color={'primary'} fontWeight={600}>
                  Comprehensive Security Assessments
              </Typography><br></br>
              <Typography component={"div"}  color={'primary'}  > At ASA, we specialize in safeguarding your digital assets by meticulously identifying and analyzing potential vulnerabilities 
              within your IT infrastructure, networks, and applications. From Firewalls to EDR solutions, our team of seasoned cybersecurity experts employs cutting-edge 
              tools and methodologies to conduct thorough assessments, pinpointing weaknesses before they can be exploited by malicious actors. 
              With our tailored approach, we provide actionable insights and recommendations to fortify your defenses, ensuring that your 
              business stays ahead of emerging threats. Trust in our expertise to fortify your cybersecurity posture and protect your valuable 
              assets from potential breaches
              <Button variant='text' style={{ display: 'inline' }} onClick={toggleExpanded} color="secondary">
                  {isExpanded ? 'Read More +' : 'Read Less -'}
              </Button><br></br><br></br>
              </Typography>

              <Typography component={"div"} color={'gray'} >
                {isExpanded ? 
                  (
                  <>
                  </>
                    ) 
                    : 
                    (
                      <>
                      <Typography component={"div"}variant='h5'  color={'secondary'} fontWeight={900}>
                      Our Comprehensive Assessments Cover:
                      </Typography>
                          <Container maxWidth={false} style={{height: "auto"}} sx={{p:3, padding: {
                            xs: '0px', // Padding for extra small screens
                            sm: '15px', // Padding for small screens
                            md: '20px', // Padding for medium screens
                            lg: '70px'} }} >
                            <List >
                              <ListItem sx={{pl: 0, alignItems: 'flex-start'}}>
                                <ListItemIcon >
                                  <CheckCircleOutline style={{ fontSize: '30px' }} color="secondary"  />
                                </ListItemIcon>
                                <div>
                                  <Typography component={"div"}color='primary' variant="h3" fontWeight={900}>1. Network Security
                                  </Typography>
                                  <Typography component={"div"}color='primary' variant="body">
                                      Ensuring the security of a network involves protecting the integrity and confidentiality of data 
                                      as it travels across various network pathways. A thorough Vulnerability Assessment (VA) within network 
                                      security encompasses the detection of weaknesses not only in routers, switches, and firewalls but also in other critical network components.
                                  </Typography>
                                </div>
                              </ListItem>

                              <ListItem sx={{pl: 0, alignItems: 'flex-start'}}>
                                <ListItemIcon >
                                  <CheckCircleOutline style={{ fontSize: '30px' }} color="secondary"  />
                                </ListItemIcon>
                                <div>
                                  <Typography component={"div"}color='primary' variant="h3" fontWeight={900}>2. Data Security</Typography>
                                  <Typography component={"div"}color='primary' variant="body">
                                  Securing data involves safeguarding confidential information from unauthorized access, disclosure, tampering, or loss. 
                                  Vulnerability assessments within data security are aimed at identifying potential flaws in databases, storage platforms, 
                                  and methods of data exchange.
                                  </Typography>
                                </div>
                              </ListItem>

                              <ListItem sx={{pl: 0, alignItems: 'flex-start'}}>
                                <ListItemIcon >
                                  <CheckCircleOutline style={{ fontSize: '30px' }} color="secondary"  />
                                </ListItemIcon>
                                <div>
                                  <Typography component={"div"}color='primary' variant="h3" fontWeight={900}>3. Cloud Security
                                  </Typography>
                                  <Typography component={"div"}color='primary' variant="body">
                                  With the migration of organizations to cloud environments, it's imperative to conduct assessments to identify vulnerabilities 
                                  in both cloud infrastructure and services. Cloud security evaluations entail scrutinizing configurations, access controls, 
                                  and potential misconfigurations that may pose risks to data integrity and confidentiality.
                                  </Typography>
                                </div>
                              </ListItem>

                              <ListItem sx={{pl: 0, alignItems: 'flex-start'}}>
                                <ListItemIcon >
                                  <CheckCircleOutline style={{ fontSize: '30px' }} color="secondary"  />
                                </ListItemIcon>
                                <div>
                                  <Typography component={"div"}color='primary' variant="h3" fontWeight={900}>4. IT Security</Typography>
                                  <Typography component={"div"}color='primary' variant="body">
                                  The domain of IT security encompasses a wide range of areas such as network security, endpoint security, 
                                  and application security. A comprehensive Vulnerability Assessment (VA) within IT security takes into account vulnerabilities
                                  present across diverse IT components, aiming to establish a resilient defense posture.  
                                  </Typography>
                                </div>
                              </ListItem>

                              <ListItem sx={{pl: 0, alignItems: 'flex-start'}}>
                                <ListItemIcon >
                                  <CheckCircleOutline style={{ fontSize: '30px' }} color="secondary"  />
                                </ListItemIcon>
                                <div>
                                  <Typography component={"div"}color='primary' variant="h3" fontWeight={900}>5. Website Vulnerability Assessment</Typography>
                                  <Typography component={"div"}color='primary' variant="body">
                                    We meticulously scrutinize your website's security 
                                    measures to identify potential vulnerabilities. Through a comprehensive examination, we assess your 
                                    website's susceptibility to various threats, including malware, SQL injection, cross-site scripting, 
                                    and other potential exploits. By providing detailed insights and actionable recommendations, we empower 
                                    you to bolster your website's defenses, safeguarding your digital assets and ensuring the protection of 
                                    your users' sensitive information.
                                    </Typography>
                                </div>
                              </ListItem>
                            </List>
                          </Container>
                      </>
                    )
                  }
                </Typography>
                <Link to = "/assessments" >
                    <Button color='secondary'  sx={{width: 130, height: 40, textTransform: 'none', borderRadius: 2 }} variant='contained'>
                    <Typography component={"div"} color={theme.palette.primary.lightest} fontWeight={600} > Learn More</Typography>
                    </Button>
                </Link>
                <br></br><br></br>
                </ScrollAnimation>
                </Grid>
                
            </Grid>
            </Container>
        </Container>  

        <Container maxWidth='false' sx={{padding: {
          xs: '10px', // Padding for extra small screens
          lg: '40px'} }}>
            <Container  maxWidth="xl"  style={{ height: "auto"}}  align="center"  >
              <Grid  container direction="row" justifyContent="center" alignItems="center" spacing={10}>
                <Grid item lg={6}>
                <ScrollAnimation animateOnce animateIn="slideInLeft">
                  <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
                    <img style={{ width: '500px', height: '416px' }} src={cyber3} alt="alternative_text"></img>
                  </Box>
                  <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <img style={{ width: '350px', height: '290px' }}src={cyber3} alt="alternative_text"></img>
                  </Box>
                </ScrollAnimation>
                </Grid>
                <Grid item lg={6} align="left">
                <ScrollAnimation animateOnce animateIn="slideInRight">
                  <Typography component={"div"}variant="h1"  color={'primary'} fontWeight={600}>
                    Endpoint Security
                  </Typography><br></br>
                  <Typography component={"div"}  color={'primary'}  > 
                  When end users accidentally let their guard down, endpoint security is typically the last line of defense in an information system network. Ensuring endpoints have the right 
                  technical controls, are properly patched, updated, and configured is crucial to maintaining confidentiality, integrity and availabilty of your systems.
                  From a few endpoints to a few thousand, we will inspect, analyze and assess each one to ensure they have adequate technical controls
                  and are compliant with industry best practices. 
                  </Typography>
                  <br></br>
                <Link to = "/endpoint" >
                  <Button color='secondary'  sx={{width: 130, height: 40, textTransform: 'none', borderRadius: 2 }} variant='contained'>
                    <Typography component={"div"} color={theme.palette.primary.lightest} fontWeight={600} > Learn More</Typography>
                  </Button>
                </Link>
                <br></br><br></br>
                </ScrollAnimation>
                </Grid>
              </Grid>
            </Container>
        </Container>

        <Container maxWidth='false' sx={{padding: {
          xs: '10px', // Padding for extra small screens
          lg: '40px'}, background: theme.palette.primary.gradient}}>
          <Container maxWidth="xl"  style={{ height: "auto"}} align="center"  >
            <Grid  container direction="row-reverse" justifyContent="center" alignItems="center" spacing={10}>
              <Grid item lg={5}>
              <ScrollAnimation animateOnce animateIn="slideInRight">
                <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
                  <img style={{ width: '500px', height: '416px' }} src={cyber2} alt="alternative_text"></img>
                </Box>
                <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                  <img style={{ width: '350px', height: '290px' }}src={cyber2} alt="alternative_text"></img>
                </Box>
                </ScrollAnimation>
              </Grid>
              <Grid item lg={6} align="left">
              <ScrollAnimation animateOnce animateIn="slideInLeft">
                <Typography component={"div"}variant="h1"  color={'primary'} fontWeight={600}>
                  Regulatory Compliance
                </Typography><br></br>
                <Typography component={"div"}  color={'primary'}  > 
                Secure sensitive healthcare data and ensure compliance with HIPAA regulations with our  
                compliance assistance service. We understand the critical importance of 
                safeguarding patient information and maintaining regulatory compliance in the healthcare sector. 
                Our specialized team works closely with your organization to conduct thorough assessments of your 
                policies, procedures, and technical safeguards, identifying areas for improvement and ensuring 
                alignment with HIPAA ePHI requirements. From risk assessments to security awareness training, we offer 
                comprehensive solutions tailored to your unique needs. Partner with us to navigate the complexities 
                of the HIPAA security rule confidently, mitigating risks and fostering trust with patients and stakeholders alike.
                </Typography>
                <br></br>
                <Link to = "/compliance" >
                  <Button color='secondary'  sx={{width: 130, height: 40, textTransform: 'none', borderRadius: 2 }} variant='contained'>
                    <Typography component={"div"} color={theme.palette.primary.lightest} fontWeight={600} > Learn More</Typography>
                  </Button>
                </Link>
                <br></br><br></br>
                </ScrollAnimation>
              </Grid>
            </Grid>
          </Container>
        </Container>

        <Container maxWidth='false' sx={{padding: {
          xs: '10px', // Padding for extra small screens
          lg: '30px'} }} >
            <Container maxWidth="xl"  style={{ height: "auto"}}  align="center"  >
              <Grid  container direction="row" justifyContent="center" alignItems="center" spacing={10}>
                <Grid item lg={6}>
                <ScrollAnimation animateOnce animateIn="slideInLeft">
                  <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
                    <img style={{ width: '600px', height: '500px' }} src={cyber6} alt="alternative_text"></img>
                  </Box>
                  <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <img style={{ width: '350px', height: '290px' }}src={cyber6} alt="alternative_text"></img>
                  </Box>
                  </ScrollAnimation>
                </Grid>
                <Grid item lg={6} align="left">
                <ScrollAnimation animateOnce animateIn="slideInRight">
                  <Typography component={"div"}variant="h1"  color={'primary'} fontWeight={600}>
                    Wireless and Network Security
                  </Typography><br></br>
                  <Typography component={"div"}  color={'primary'}  > 
                  Network security focuses on safeguarding the integrity and confidentiality of data during its transmission over a network. Your network 
                  is your biggest vulnerabilty when it comes to information systems, especially a wireless one, because it is the primary gateway into your sensitive systems and data. 
                  When inspecting your network, We'll carefully analyze your network routers, wireless access points, switches, firewalls, and other networking devices, along with their 
                  configurations, to ensure they are up to date, properly configured, and ahere to industry best practices.
                  </Typography><br></br>
                  <Link to = "/networksecurity" >
                  <Button color='secondary'  sx={{width: 130, height: 40, textTransform: 'none', borderRadius: 2 }} variant='contained'>
                    <Typography component={"div"} color={theme.palette.primary.lightest} fontWeight={600} > Learn More</Typography>
                  </Button>
                </Link>
                <br></br><br></br>
                </ScrollAnimation>
                </Grid>
              </Grid>
            </Container>
        </Container>

        <Container sx={{padding: {
          xs: '10px', // Padding for extra small screens
          lg: '70px'} }} maxWidth="false" style={{background: theme.palette.primary.gradient, height: "auto"}} align="center"  >
          <Container maxWidth="xl" style={{ height: "auto"}}  align="center"  >
            <Grid  container direction="row-reverse" justifyContent="center" alignItems="center" spacing={10}>
              <Grid item lg={5}>
              <ScrollAnimation animateOnce animateIn="slideInRight">
                <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
                  <img style={{ width: '500px', height: '416px' }} src={cyber4} alt="alternative_text"></img>
                </Box>
                <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                  <img style={{ width: '350px', height: '290px' }}src={cyber4} alt="alternative_text"></img>
                </Box>
                </ScrollAnimation>
              </Grid>
              <Grid item lg={6} align="left">
              <ScrollAnimation animateOnce animateIn="slideInLeft">
                <Typography component={"div"}variant="h1"  color={'primary'} fontWeight={600}>
                  Training and Awareness
                </Typography><br></br>
                <Typography component={"div"}  color={'primary'}  > 
                Elevate your organization's cybersecurity posture through our tailored training and awareness 
                programs. At ASA, we recognize that effective cybersecurity begins with a knowledgeable 
                and vigilant workforce. Our comprehensive training modules are designed to empower your employees with 
                the skills and awareness needed to recognize and mitigate potential security threats. From phishing 
                simulations to best practices in data protection, our engaging sessions ensure that your team remains 
                informed and equipped to navigate the evolving threat landscape. With our proactive approach to raising 
                awareness, your organization can cultivate a culture of security, reducing the likelihood of breaches and 
                safeguarding your valuable assets. Invest in your team's readiness with our training solutions and bolster 
                your defenses against cyber threats.
                </Typography>
                <br></br>
                <Link to = "/training" >
                  <Button color='secondary'  sx={{width: 130, height: 40, textTransform: 'none', borderRadius: 2 }} variant='contained'>
                    <Typography component={"div"} color={theme.palette.primary.lightest} fontWeight={600} > Learn More</Typography>
                  </Button>
                </Link>
                <br></br><br></br>
                </ScrollAnimation>
              </Grid>
              
            </Grid>
          </Container>
        </Container>

        <Container maxWidth='false' sx={{padding: {
          xs: '10px', // Padding for extra small screens
          lg: '50px'} }} >
          <Container maxWidth="xl"  style={{ height: "auto"}}  align="center"  >
            <Grid  container direction="row" justifyContent="center" alignItems="center" spacing={10}>
              <Grid item lg={6}>
              <ScrollAnimation animateOnce animateIn="slideInLeft">
                <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
                  <img style={{ width: '500px', height: '416px' }} src={cyber8} alt="alternative_text"></img>
                </Box>
                <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                  <img style={{ width: '350px', height: '290px' }}src={cyber8} alt="alternative_text"></img>
                </Box>
                </ScrollAnimation>
              </Grid>
              <Grid item lg={6} align="left">
              <ScrollAnimation animateOnce animateIn="slideInRight">
                <Typography component={"div"}variant="h1"  color={'primary'} fontWeight={600}>
                Incident Response Planning
                </Typography><br></br>
                <Typography component={"div"}  color={'primary'}  > 
                At ASA we prioritize not just 
                defense, but also resilience. In an ever-evolving digital landscape, continuity planning and 
                development are imperative. We can help you develop effective Incident Response, 
                Disaster Recovery, and Continuity of Operations plans. Our services are meticulously crafted to fortify your organization, 
                guaranteeing that essential functions continue seamlessly, even during crises. Trust us to help plan 
                your continuity in the most challenging of cyber landscapes.
                </Typography>
                <br></br>
                <Link to = "/continuity" >
                  <Button color="secondary" sx={{width: 130, height: 40, textTransform: 'none', borderRadius: 2 }} variant='contained'>
                    <Typography component={"div"} color={theme.palette.primary.lightest} fontWeight={600} > Learn More</Typography>
                  </Button>
                </Link>
                <br></br><br></br>
                </ScrollAnimation>
              </Grid>
            </Grid>
          </Container>
        </Container>

        <Container maxWidth='false' sx={{padding: {
          xs: '10px', // Padding for extra small screens
          lg: '70px'}, background: theme.palette.primary.gradient}}>
          <Container maxWidth="xl"  style={{ height: "auto"}}  align="center"  >
            <Grid  container direction="row-reverse" justifyContent="center" alignItems="center" spacing={10}>
            <Grid item lg={6}>
            <ScrollAnimation animateOnce initiallyVisible animateIn="slideInRight">
                <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
                <img style={{ width: '500px', height: '416px' }} src={cyber7} alt="alternative_text"></img>
                </Box>
                <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <img style={{ width: '350px', height: '290px' }}src={cyber7} alt="alternative_text"></img>
                </Box>
              </ScrollAnimation>
              </Grid>
              <Grid item lg={5} align="left">
              <ScrollAnimation animateOnce animateIn="slideInLeft">
                <Typography component={"div"}variant="h1"  color={'primary'} fontWeight={600}>
                Cyber Risk Quantification
                </Typography><br></br>
                <Typography component={"div"}  color={'primary'}  > 
                No organization on earth has the resources to have perfect confidentiality, integrity and availablity for their information systems.
                As such, careful Risk Management is an essential function in determining cybersecurity resource allocation. At ASA, we will guide your
                organization through the risk management process, ensuring resources are allocated proportionally to the unique threats your organization faces.
                Our risk managment framework will enable you to precisely identify your most critical assets and secure them against the most likely threats.
                </Typography>
                <br></br>
                <Link to = "/crq" >
                <Button color="secondary" sx={{width: 130, height: 40, textTransform: 'none', borderRadius: 2 }} variant='contained'>
                    <Typography component={"div"} color={theme.palette.primary.lightest} fontWeight={600} > Learn More</Typography>
                </Button>
                </Link>
                <br></br><br></br><br></br><br></br>
                </ScrollAnimation>
              </Grid>
             
            </Grid>
          </Container>
        </Container>
      <Anchor/>
    </div>
    );
  }
