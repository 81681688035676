import React, { useState, useEffect } from "react";

export const ErrorBoundary = (props) => {
  const [failed, setFailed] = useState(false);

  useEffect(() => {
    const componentDidCatch = (error, info) => {
      setFailed(true);
      // You can log or handle the error here if needed
      console.error("Error caught in ErrorBoundary:", error);
    };

    // Setup componentDidCatch as effect
    window.addEventListener("error", componentDidCatch);

    // Clean up
    return () => {
      window.removeEventListener("error", componentDidCatch);
    };
  }, []); // Empty dependency array ensures this effect runs only once

  if (failed) {
    return <div>ASA Consulting has encountered an error. Try a different browser.</div>;
  } else {
    return props.children;
  }
};


