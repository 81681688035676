import {Link} from "react-router-dom";
import * as React from 'react';
import { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Anchor } from "./Anchor";

import { 
  Typography, 
  Container, 
  Button,
  Box,
  Fade,
  ListItem, 
  List,
  ListItemIcon, 
  Slide,
  Grid
 } from '@mui/material';
 import { 
  East,
  CheckCircleOutline
} from '@mui/icons-material';
import wallpaper from './images/asabg.png'
import cyber13 from './images/cyber13.png'
import cyber11 from './images/cyber11.png'
import cyber3 from './images/cyber3.png'
import './App.css';



export function Endpoint() { 

  const theme = useTheme()

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

    return (
      <div>
      <Container maxWidth={false} style={{backgroundImage: `url(${wallpaper})`,backgroundSize: "cover", alignItems: "center", 
      minHeight: "400px", display: 'flex'}} sx={{p: 5}} align="center"  >
        <Slide direction='right' unmountOnExit={true}
          {...{timeout: {enter:1000, exit:1000} }} in={true}> 
          <Container  maxWidth='md' sx={{borderRadius: 2, p: 4}}>
              <Typography component={"div"} align='left' variant='title' color={theme.palette.primary.lightest}  fontWeight='900' > 
                Endpoint Security
              </Typography>           
          </Container>
        </Slide>
      </Container>

      <Container maxWidth={false} style={{height: "auto"}} sx={{padding: {
          xs: '15px', // Padding for extra small screens
          lg: '70px'}}} >
        <Grid  container direction="row-reverse" justifyContent="center" alignItems="center" spacing={1}>
          <Grid item md={5}>
            <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
                <img style={{ width: '600px', height: '500px' }} src={cyber3} alt="alternative_text"></img>
              </Box>
              <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <img style={{ width: '350px', height: '290px' }}src={cyber3} alt="alternative_text"></img>
              </Box>
          </Grid>
          <Grid item md={6}   align="left">
            <Typography component={"div"}variant="h1"  color={'primary'} fontWeight={800}>
                What Are Endpoints?
            </Typography><br></br>
            <Typography component={"div"}  color={'primary'}>
            Endpoints are essentially entry and exit points for data traveling across networks. This includes your computer, smartphone, tablet, 
            or any device that connects to a network. When you send an email, stream a video, or browse a 
            website, data is transmitted from your device (the endpoint) to its destination, which could be another device, a server, 
            or a website (another endpoint). Likewise, when you receive data, it travels from its source endpoint to your device. 
            Endpoints play a vital role in enabling seamless connectivity and interaction in our digital world, and keeping them secure is essential.
            </Typography><br></br>
            <Typography component={"div"} color={'primary'} >
                Here are some examples:
            </Typography>
              <List>
                <ListItem >
                  <ListItemIcon >
                    <East style={{ fontSize: '30px' }} color="secondary"  />
                  </ListItemIcon >
                  <div>
                    <Typography component={"div"}color='primary'>Computers
                    </Typography>
                  </div>
                </ListItem>

                <ListItem >
                  <ListItemIcon >
                    <East style={{ fontSize: '30px' }} color="secondary"  />
                  </ListItemIcon >
                  <div>
                    <Typography component={"div"}color='primary'>Mobile Devices
                    </Typography>
                  </div>
                </ListItem>


                <ListItem >
                  <ListItemIcon >
                    <East style={{ fontSize: '30px' }} color="secondary"  />
                  </ListItemIcon >
                  <div>
                    <Typography component={"div"}color='primary'>Web Servers
                    </Typography>
                  </div>
                </ListItem>

                <ListItem >
                  <ListItemIcon >
                    <East style={{ fontSize: '30px' }} color="secondary"  />
                  </ListItemIcon >
                  <div>
                    <Typography component={"div"}color='primary'>Routers
                    </Typography>
                  </div>
                </ListItem>

                <ListItem >
                  <ListItemIcon >
                    <East style={{ fontSize: '30px' }} color="secondary"  />
                  </ListItemIcon >
                  <div>
                    <Typography component={"div"}color='primary'> Firewalls
                    </Typography>
                  </div>
                </ListItem>
              </List>
              <br></br>
            </Grid>
          </Grid>
        </Container>



      <Container maxWidth='false'  sx={{padding: {
          xs: '15px', 
          lg: '70px'}, background: theme.palette.primary.gradient}}>
        <Fade unmountOnExit={true} style={{ transformOrigin: '3 3 3', transitionDelay: '0ms' }}
              {...{timeout: {enter:1000, exit:1000} }} in={true}> 
            <Container maxWidth="xl"  style={{ height: "auto"}}  align="center"  >
              <Grid  container direction="row"alignItems="center" spacing={10}>
                <Grid item lg={6} >
                 <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
                    <img style={{ width: '600px', height: '470px' }} src={cyber13} alt="alternative_text"></img>
                  </Box>
                  <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <img style={{ width: '300px', height: '200px' }}src={cyber13} alt="alternative_text"></img>
                  </Box>
                </Grid>
                <Grid item lg={6} align="left">
                <Typography component={"div"}variant="h3"  color={'secondary'} fontWeight={800}>
                Why Does Your Business Need Endpoint Security Services?
                </Typography><br></br>
                <Typography component={"div"}variant="h1"  color={'primary'} fontWeight={800}>
                Endpoints are the last line of defense.
                </Typography><br></br>
                  <Typography component={"div"} color={'primary'}  > 
                  If an employee accidentally clicks on a malicious link or unwittingly uploads malware, the last line of defense is going to be
                  the security implementations on that endpoint. Intermixing key strategies for endpoint security within your network infrastructure is essential. 
                  By integrating robust authentication measures alongside continuous monitoring protocols, we fortify 
                  your defenses against evolving threats. Whether it's implementing behavior-based detection or deploying 
                  advanced encryption methods, our approach encompasses a dynamic range of protective measures. This 
                  proactive stance not only safeguards against potential breaches but also bolsters the resilience of 
                  your network ecosystem.
                  </Typography><br></br>
                 
                  <br></br>
                </Grid>
              </Grid>
            </Container>
          </Fade>
        </Container>

        <Container maxWidth={false} style={{height: "auto"}}  sx={{padding: {
          xs: '15px', // Padding for extra small screens
          lg: '70px'}}} >
        <Grid  container direction="row-reverse" justifyContent="center" alignItems="center" spacing={1}>
          <Grid item lg={5}>
            <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
                <img style={{ width: '600px', height: '500px' }} src={cyber11} alt="alternative_text"></img>
              </Box>
              <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <img style={{ width: '350px', height: '290px' }}src={cyber11} alt="alternative_text"></img>
              </Box>
            </Grid>
          <Grid item lg={6}   align="left">
          <Typography component={"div"}variant="h1"  color={'primary'} fontWeight={800}>
              Importance of Endpoint Security
          </Typography><br></br>

                <ListItem sx={{alignItems: 'flex-start'}}>
                  <ListItemIcon >
                    <CheckCircleOutline  color="secondary"  />
                  </ListItemIcon>
                  <div>
                    <Typography component={"div"}color='primary' variant="h3" fontWeight={900}>1. Prevention
                    </Typography>
                    <Typography component={"div"}color='primary' variant="body"> Endpoint security prevents a cyberattack from exploiting your 
                    devices and network. Strong endpoint security, like EDR tools, stop malware and notify the user of the detected malware
                    before it has a chance to cause damage.
                    </Typography>
                  </div>
                </ListItem>
    
                <ListItem sx={{alignItems: 'flex-start'}}>
                  <ListItemIcon >
                    <CheckCircleOutline  color="secondary"  />
                  </ListItemIcon>
                  <div>
                    <Typography component={"div"}color='primary' variant="h3" fontWeight={900}>2. Instant Detection and Response
                    </Typography>
                    <Typography component={"div"}color='primary' variant="body">Implementing strong endpoint security enables organizations 
                      to immediately and autonomously detect and respond to cyberattacks, minimizing the likelihood of security breaches.
                    </Typography>
                  </div>
                </ListItem>

                <ListItem sx={{alignItems: 'flex-start'}}>
                  <ListItemIcon >
                    <CheckCircleOutline  color="secondary"  />
                  </ListItemIcon>
                  <div>
                    <Typography component={"div"}color='primary' variant="h3" fontWeight={900}>3. Regulatory Compliance</Typography>
                    <Typography component={"div"}color='primary' variant="body">Many industries have stringent compliance requirements. 
                    Effective endpoint security helps organizations comply with regulations and avoid legal consequences.
                    </Typography>
                  </div>
                </ListItem>


                <ListItem sx={{alignItems: 'flex-start'}}>
                  <ListItemIcon >
                    <CheckCircleOutline  color="secondary"  />
                  </ListItemIcon>
                  <div>
                    <Typography component={"div"}color='primary' variant="h3" fontWeight={900}>4. Extra Layer of Defense
                    </Typography>
                    <Typography component={"div"}color='primary' variant="body"> If malware slips past your firewalls and IDS, endpoint security may 
                    be the last line of defense for your organization.
                    </Typography>
                  </div>
                </ListItem>
                <br></br>
                <Link to = "/contact" >
                  <Button color="secondary" sx={{width: 180, height: 40, textTransform: 'none', borderRadius: 2 }} variant='contained'>
                    <Typography component={"div"} color={theme.palette.primary.lightest} fontWeight={600} > Talk to an expert</Typography>
                  </Button>
                </Link>
              <br></br>
              <br></br>
              <br></br>
            </Grid>
          </Grid>
        </Container>
        <Anchor/>
      </div>
      )}