import {Link} from "react-router-dom";
import * as React from 'react';
import { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTheme } from '@mui/material/styles';
import { Anchor } from "./Anchor";
import { 
  Typography, 
  Container, 
  Button,
  Box,
  Fade,
  ListItem, 
  ListItemIcon, 
  Slide,
  Grid
 } from '@mui/material';
import Divider from '@mui/material/Divider';
import './App.css';

import wallpaper from './images/asabg.png'
import cyber3 from './images/cyber3.png'
import cyber4 from './images/cyber4.png'
import cyber10 from './images/cyber10.png'


export function Assess() {

  const theme = useTheme()

  const [expanded, setExpanded] = useState(Array(4).fill(false));
  const [expanded1, setExpanded1] = useState(true);

  const handleChange = (index) => (event, isExpanded) => {
    setExpanded((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      newExpanded[index] = isExpanded;
      return newExpanded;
    });
  };

  const handleChange1 = () =>{
    setExpanded1(!expanded1)
  };


  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

    return (
      <div>
      <Container maxWidth={false} style={{backgroundImage: `url(${wallpaper})`,backgroundSize: "cover", alignItems: "center", 
      minHeight: "400px", display: 'flex'}} sx={{p: 5}} align="center"  >
        <Slide direction='right' unmountOnExit={true}
          {...{timeout: {enter:1000, exit:1000} }} in={true}> 
          <Container  maxWidth='md' sx={{borderRadius: 2, p: 4}}>
              <Typography component={"div"} align='left' variant='title' color={theme.palette.primary.lightest}  fontWeight='900' > 
              Vulnerability Assessments
              </Typography>           
          </Container>
        </Slide>
      </Container>
        
      <Container maxWidth={false} style={{ height: "auto"}} sx={{padding: {
          xs: '15px', // Padding for extra small screens
          lg: '70px'} }}>
        <Fade unmountOnExit={true} style={{ transformOrigin: '3 3 3', transitionDelay: '0ms' }}
              {...{timeout: {enter:1000, exit:1000} }} in={true}>
          <Grid  container direction="row" justifyContent="center" alignItems="center" spacing={5}>
            <Grid item md={5} align="left">
            <Typography component={"div"}variant="h3"  color={'secondary'} fontWeight={600}>
                What is Vulnerability Assessment?
              </Typography><br></br>
              <Typography component={"div"}variant="h1"   color={'primary'} fontWeight={800}>
                Vulnerability assessments assist in pinpointing bugs and threats in the IT environment before cyberpunks do. 
              </Typography><br></br>
              <Typography component={"div"}  color={'primary'}  > 
              Vulnerability Assessment (VA) is a systematic process of identifying, quantifying, and prioritizing vulnerabilities in a system. These vulnerabilities may exist in various components such as software, networks, applications, and configurations. The primary objective of a VA is to evaluate the security posture of an organization, allowing for the timely identification and mitigation of potential risks.
              </Typography>
              <br></br>
            </Grid>
            <Grid item md={6}>
              <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
                <img style={{ width: '600px', height: '500px' }} src={cyber3} alt="alternative_text"></img>
              </Box>
              <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <img style={{ width: '350px', height: '290px' }}src={cyber3} alt="alternative_text"></img>
              </Box>
            </Grid>
          </Grid>
        </Fade>
      </Container>

      <Divider></Divider>

      <Container maxWidth={false} style={{ height: "auto"}} sx={{padding: {
          xs: '15px', // Padding for extra small screens
          lg: '70px'}, background: theme.palette.primary.gradient}}>
      <Grid  container direction="row" justifyContent="center" alignItems="top" spacing={5}>
          <Grid item md={5} align="center">
            <Typography component={"div"}align="left" color='primary' fontWeight={800} variant="h1">Types of Vulnerability Assessment</Typography><br></br><br></br>
            <Box align="left"  sx={{ display: { sm: 'block', xs: 'none' } }}>
              <img style={{ width: '350px', height: '290px' }} src={cyber10} alt="alternative_text"></img>
            </Box>
            <Box align="left"  sx={{ display: { xs: 'block', sm: 'none' } }}>
              <img style={{ width: '350px', height: '290px' }}src={cyber10} alt="alternative_text"></img>
            </Box>
          </Grid>
          <Grid  item md={5}>
          <Accordion defaultExpanded={true} onChange={handleChange1} align='left' sx={{backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
              <AccordionSummary
                sx={{flexDirection: 'row-reverse'}}
                expandIcon={expanded1 ? <RemoveCircleOutlineIcon color="secondary"/> : <AddCircleOutlineIcon  color="secondary"/>}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography component={"div"}color={'primary'} variant="h6" fontWeight={900}  marginLeft={2}>Network Security</Typography> 
              </AccordionSummary>
              <AccordionDetails sx={{color: theme => theme.palette.primary.main}}>
              Network security focuses on safeguarding the integrity and confidentiality of data during its transmission over a network. A comprehensive VA 
              in the context of network security involves scanning for vulnerabilities in routers, switches, firewalls, and other network devices.
              </AccordionDetails>
            </Accordion >
            <Accordion expanded={expanded[1]} onChange={handleChange(1)} align='left' sx={{backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
              <AccordionSummary
                sx={{flexDirection: 'row-reverse'}}
                expandIcon={expanded[1] ? <RemoveCircleOutlineIcon color="secondary"/> : <AddCircleOutlineIcon  color="secondary"/>}
                aria-controls="panel1-content"
                id="panel1-header"
              >
               <Typography component={"div"}color={'primary'} variant="h6" fontWeight={900}  marginLeft={2}>Data Security </Typography> 
              </AccordionSummary>
              <AccordionDetails sx={{color: theme => theme.palette.primary.main}}>
              Data security is about protecting sensitive information from unauthorized access, disclosure, alteration, or destruction. Vulnerability 
              assessments in data security address potential weaknesses in databases, storage systems, and data transfer mechanisms.
              </AccordionDetails>
            </Accordion >
            <Accordion expanded={expanded[2]} onChange={handleChange(2)} align='left' sx={{backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
              <AccordionSummary
                sx={{flexDirection: 'row-reverse'}}
                expandIcon={expanded[2] ? <RemoveCircleOutlineIcon color="secondary"/> : <AddCircleOutlineIcon  color="secondary"/>}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography component={"div"}color={'primary'} variant="h6" fontWeight={900} marginLeft={2}>Cloud Security</Typography> 
              </AccordionSummary>
              <AccordionDetails sx={{color: theme => theme.palette.primary.main}}>
              As organizations migrate to the cloud, it becomes crucial to assess vulnerabilities in cloud infrastructure and services. Cloud security 
              assessments involve evaluating configurations, access controls, and potential misconfigurations that could expose data to risk.
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded[3]} onChange={handleChange(3)} align='left' sx={{backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
              <AccordionSummary
                sx={{flexDirection: 'row-reverse'}}
                expandIcon={expanded[3] ? <RemoveCircleOutlineIcon color="secondary"/> : <AddCircleOutlineIcon  color="secondary"/>}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography component={"div"}color={'primary'} variant="h6" fontWeight={900}  marginLeft={2}>IT Security</Typography> 
              </AccordionSummary>
              <AccordionDetails sx={{color: theme => theme.palette.primary.main}}>
              IT security encompasses a broad spectrum, including network security, endpoint security, and application security. A holistic VA in 
              IT security considers vulnerabilities across various IT components to ensure a robust defense.
              </AccordionDetails>
            </Accordion >
            <Accordion expanded={expanded[4]} onChange={handleChange(4)} align='left' sx={{backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
              <AccordionSummary
                sx={{flexDirection: 'row-reverse'}}
                expandIcon={expanded[4] ? <RemoveCircleOutlineIcon color="secondary"/> : <AddCircleOutlineIcon  color="secondary"/>}
                aria-controls="panel1-content"
                id="panel1-header"
              >
               <Typography component={"div"}color={'primary'} variant="h6" fontWeight={900}  marginLeft={2}>Website Security</Typography> 
              </AccordionSummary>
              <AccordionDetails sx={{color: theme => theme.palette.primary.main}}>
                Websites are susceptible to a variety of threats. Our services include scanning web applications, 
                identifying vulnerabilities, and recommending measures to secure websites against potential exploits.
              </AccordionDetails>
            </Accordion >
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth={false} style={{height: "auto"}} sx={{padding: {
          xs: '15px', // Padding for extra small screens
          lg: '70px'} }} >
        <Grid  container direction="row" justifyContent="center" alignItems="center" spacing={1}>
          <Grid item md={6}   align="left">
          <Typography component={"div"}variant="h1"  color={'primary'} fontWeight={800}>
              Importance of Vulnerability Assessment
          </Typography><br></br>
    
                <ListItem sx={{alignItems: 'flex-start'}}>
                  <ListItemIcon >
                    <CheckCircleOutlineIcon style={{ fontSize: '30px' }} color="secondary"  />
                  </ListItemIcon>
                  <div>
                    <Typography component={"div"}color='primary' variant="h3" fontWeight={900}>1. Risk Mitigation
                    </Typography>
                    <Typography component={"div"}color='primary' variant="body">Identifying vulnerabilities allows organizations 
                      to prioritize and address potential risks, minimizing the likelihood of security breaches.
                    </Typography>
                  </div>
                </ListItem>

                <ListItem sx={{alignItems: 'flex-start'}}>
                  <ListItemIcon >
                    <CheckCircleOutlineIcon style={{ fontSize: '30px' }} color="secondary"  />
                  </ListItemIcon>
                  <div>
                    <Typography component={"div"}color='primary' variant="h3" fontWeight={900}>2. Regulatory Compliance</Typography>
                    <Typography component={"div"}color='primary' variant="body">Many industries have stringent compliance requirements. 
                    Conducting regular vulnerability assessments helps organizations comply with regulations and avoid legal consequences.
                    </Typography>
                  </div>
                </ListItem>


                <ListItem sx={{alignItems: 'flex-start'}}>
                  <ListItemIcon >
                    <CheckCircleOutlineIcon style={{ fontSize: '30px' }} color="secondary"  />
                  </ListItemIcon>
                  <div>
                    <Typography component={"div"}color='primary' variant="h3" fontWeight={900}>3. Proactive Security
                    </Typography>
                    <Typography component={"div"}color='primary' variant="body">A proactive approach to security involves identifying and 
                    addressing vulnerabilities before they can be exploited, minimizing the impact of potential attacks.
                    </Typography>
                  </div>
                </ListItem>

                <ListItem sx={{alignItems: 'flex-start'}}>
                  <ListItemIcon >
                    <CheckCircleOutlineIcon style={{ fontSize: '30px' }} color="secondary"  />
                  </ListItemIcon>
                  <div>
                  <Typography component={"div"}color='primary' variant="h3" fontWeight={900}>4. Cost Savings</Typography>
                  <Typography component={"div"}color='primary' variant="body">
                  Save costs by proactively identifying and addressing security risks, 
                  preventing potential breaches and associated financial losses.  
                  </Typography>
                  </div>
                </ListItem>

            <br></br>
          </Grid>
          <Grid item md={4}>
            <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
                <img style={{ width: '600px', height: '500px' }} src={cyber4} alt="alternative_text"></img>
              </Box>
              <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <img style={{ width: '350px', height: '290px' }}src={cyber4} alt="alternative_text"></img>
              </Box>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth={false} sx={{padding: {
          xs: '15px', // Padding for extra small screens
          lg: '70px'}, background: theme.palette.primary.gradient}}>
        
        <Grid  container direction="row" justifyContent="center" alignItems="center" spacing={5}>
          <Grid item md={11} align="left">
            <Typography component={"div"}variant="h1"   color={'primary'} fontWeight={800}>
              Conclusion
            </Typography><br></br>
            <Typography component={"div"}  sx={{color: theme => theme.palette.primary.main}}  > 
            Vulnerability Assessment (VA) is a systematic process of identifying, quantifying, and prioritizing vulnerabilities 
            in a system. These vulnerabilities may exist in various components such as software, networks, applications, and configurations. 
            The primary objective of a VA is to evaluate the security posture of an organization, allowing for the timely identification 
            and mitigation of potential risks. <br></br><br></br>Trust ASA to identify and fix vulnerabilities in your organization before the bad guys do.
            </Typography>
            <br></br>
            <Link to = "/contact" >
              <Button color="secondary" sx={{width: 180, height: 40, textTransform: 'none', borderRadius: 2 }} variant='contained'>
                <Typography component={"div"} color={theme.palette.primary.lightest} fontWeight={600} > Talk to an expert</Typography>
              </Button>
            </Link>
            <br></br><br></br><br></br><br></br>
          </Grid>
        </Grid>
      </Container>
    <Anchor/>
  </div>
    );
  }
  


