import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import { Anchor } from "./Anchor";
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import { useState, useEffect } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { 
  Typography, 
  Container, 
  Button,
  TextField,
  Fade,
  Box,
  Paper,
 } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import './App.css';
import wallpaper from './images/asabg.png'

export function Contact() {

const url = process.env.REACT_APP_URL || 'https://2502zgvakl.execute-api.us-east-2.amazonaws.com/production'

let theme = useTheme()

const initalFormState ={
  "fname" : '',
  "lname" : '',
  "company" : '',
  "email" : '',
  "phone" : '',
  "employees" : '',
  "message" : '',
  "captchaToken" : ''
}

const [empError, setEmpError] = useState();
const [fNameError, setFnameError] = useState();
const [lNameError, setLnameError] = useState();
const [emailError, setEmailError] = useState();
const [phoneError, setPhoneError] = useState();
const [form, setForm] = useState(initalFormState);
const [open, setOpen] = useState(false);
const [progress, setProgress] = useState(false);

const isFormValid = form.fname !== '' && form.lname !== '' && form.email !== '' 
&& form.phone !== '' && !fNameError && !lNameError && !emailError && !phoneError && !empError && form.captchaToken !== '';

useEffect(()=>{
  window.scrollTo(0, 0);
},[])

const handleClick = (e) => {
  setProgress(true)
  fetch(`${url}/submitForm`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
      body: JSON.stringify(form),
  })
  .then(response => {
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }
      setOpen(true);
      setProgress(false)
      setForm(initalFormState)
      return response;
  })
  .catch(error => {
    alert('Submission Failure. Please try again later.')
      console.error('There was a problem with the fetch operation:', error);
  });
};

const handleCaptcha = (e) => {
  setForm(prevForm => ({
    ...prevForm,
    "captchaToken" : e
  }));
  };

const handleClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setOpen(false);
};



const handleChange = (e) => {
  switch (e.target.id) {
    case 'employees':
      const emp = e.target.value;
      const empId = e.target.id;
        setForm(prevForm => ({
          ...prevForm,
          [empId]: emp
        }));
        const isNum = /^\d+$/.test(e.target.value)
        if(isNum){setEmpError(false)}else(setEmpError(true))
      break;
    case 'fname':
      const fn = e.target.value;
      const fnId = e.target.id;
        setForm(prevForm => ({
          ...prevForm,
          [fnId]: fn
        }));
        const isLetter = /^[a-zA-Z]+$/.test(e.target.value)
        if(isLetter){setFnameError(false)}else(setFnameError(true))
      break;
    case 'lname':
      const ln = e.target.value;
      const lnId = e.target.id;
        setForm(prevForm => ({
          ...prevForm,
          [lnId]: ln
        }));
        const isAlsoLetter = /^[a-zA-Z]+$/.test(e.target.value)
        if(isAlsoLetter){setLnameError(false)}else(setLnameError(true))
      break;
    case 'company':
      const cn = e.target.value;
      const cnId = e.target.id;
        setForm(prevForm => ({
          ...prevForm,
          [cnId]: cn
        }));
      break;
    case 'message':
      const mes = e.target.value;
      const mesId = e.target.id;
        setForm(prevForm => ({
          ...prevForm,
          [mesId]: mes
        }));
        break;
    case 'phone':
      const pn = e.target.value;
      const pnId = e.target.id;
        setForm(prevForm => ({
          ...prevForm,
          [pnId]: pn
        }));
        const isPhoneNum = /^\d{3}-\d{3}-\d{4}$/.test(e.target.value)
        if(isPhoneNum){setPhoneError(false)}else(setPhoneError(true))
          break;
    case 'email':
      const em = e.target.value;
      const emId = e.target.id;
        setForm(prevForm => ({
          ...prevForm,
          [emId]: em
        }));
        const isEmail = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/.test(e.target.value)
        if(isEmail){setEmailError(false)}else(setEmailError(true))
          break;
      default: break
  }
};


const action = (
  <React.Fragment>
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  </React.Fragment>
);

  return (
    <div>
      <Container maxWidth={false} style={{backgroundImage: `url(${wallpaper})`,backgroundSize: "cover", alignItems: "center", 
      minHeight: "400px", display: 'flex'}} sx={{p: 5}} align="center"  >
       <Fade unmountOnExit={true} style={{ transformOrigin: '3 3 3' }}
          {...{timeout: {enter:1000, exit:1000} }} in={true}> 
          <Container  maxWidth='md' sx={{borderRadius: 2, p: 4}}>
              <Typography component={"div"} align='left' variant='title' color={theme.palette.primary.lightest}  fontWeight='900' > 
              Contact Us
              </Typography>           
          </Container>
        </Fade>
      </Container>

      <Container maxWidth={'md'} align="left" sx={{padding: {
          xs: '25px', // Padding for extra small screens
          lg: '40px'} }}>
          <Typography style={{display: 'inline'}} component={"span"}variant='h3'>
          Thank you for your interest in ASA Cybersecurity.
          Please complete the form below to have the appropriate team member contact you regarding your needs. <br></br><br></br>
          You may also call <Typography variant='h3' component={"span"} sx={{ textDecoration: 'none', transition: 'text-decoration 0.3s', 
                        '&:hover': { textDecoration: 'underline' } }} color={theme => theme.palette.primary.lighter}>
                            <a
                            href={'tel: 402-403-7055'}
                            style={{ textDecoration: 'none', color: theme.palette.primary.main }}
                            >
                                <stong>(402) 403-7055</stong>
                            </a>
                        </Typography>   to schedule an appointment.
          </Typography>
      </Container>

      <Container maxWidth={'lg'} align="center" sx={{ padding: {
          xs: '15px', // Padding for extra small screens
          sm: '15px', // Padding for small screens
          md: '20px', // Padding for medium screens
          lg: '10px'} }} 
          >
        <Paper  elevation={1} sx={{ borderRadius: 7, boxShadow: '0px 2px 10px rgba(112,66,252,.44)',p: 5//, minHeight: '600px', minWidth: '350px'
                        }}  style={{ overflow: 'auto', wordWrap: 'break-word' }}>
          <Typography component={"div"}variant='h2'>
          Send a Message to Our Team
          </Typography>
          <br></br>
          <Box maxWidth={'lg'} component="form"
          noValidate
          onSubmit={handleClick}
          autoComplete="off"

          >
            <div>
              <div>
              <FormControl sx={{ m: 1}} variant="outlined">
              <TextField
                sx={{
                  width: { sm: '300px', md: '400px', lg: '500px' },
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: theme.palette.secondary.main,
                    },
                  },
                  '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red', // Red border for invalid input
                  },
                }}
                required
                color={'secondary'}
                value={form.fname}
                onChange={handleChange}
                id="fname"
                label="First Name"
                error={fNameError} // Pass the error state to the error prop
                helperText={fNameError ? 'Please enter only letters' : ''} // Display helper text if error is true
                inputProps={{ maxLength: 50 }}
              />

              </FormControl>
              <FormControl sx={{ m: 1}} variant="outlined">
                <TextField sx={{
                  width: { sm: '300px', md: '400px', lg: '500px' },
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: theme.palette.secondary.main,
                    },
                  },
                  '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red', // Red border for invalid input
                  },
                }}
                  required
                  color='secondary'
                  value={form.lname}
                  onChange={handleChange}
                  id="lname"
                  label="Last Name"
                  error={lNameError} // Pass the error state to the error prop
                  helperText={lNameError ? 'Please enter only letters' : ''} // Display helper text if error is true
                  inputProps={{ maxLength: 50 }}
                />
              
              </FormControl>
              </div>
              <div>
              <FormControl sx={{ m: 1}} variant="outlined">
                <TextField sx={{
                  width: { sm: '300px', md: '400px', lg: '500px' },
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: theme.palette.secondary.main,
                    },
                  },
                  '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red', // Red border for invalid input
                  },
                }}
                  color='secondary'
                  value={form.company}
                  onChange={handleChange}
                  id="company"
                  label="Company Name"
                  inputProps={{ maxLength: 50 }}
                />
              </FormControl>
              <FormControl sx={{ m: 1 }} variant="outlined">
              
                < TextField sx={{
                  width: { sm: '300px', md: '400px', lg: '500px' },
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: theme.palette.secondary.main,
                    },
                  },
                  '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red', // Red border for invalid input
                  },
                }}
                  required
                  color='secondary'
                  value={form.email}
                  onChange={handleChange}
                  id="email"
                  label="Email"
                  error={emailError} // Pass the error state to the error prop
                  helperText={emailError ? 'Please enter a valid email address' : ''} // Display helper text if error is true
                  inputProps={{ maxLength: 50 }}
                />
              </FormControl>
              </div>
              
              <div>
              <FormControl sx={{ m: 1 }} variant="outlined">
                <TextField sx={{
                  width: { sm: '300px', md: '400px', lg: '500px' },
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: theme.palette.secondary.main,
                    },
                  },
                  '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red', // Red border for invalid input
                  },
                }}
                  required
                  color='secondary'
                  value={form.phone}
                  onChange={handleChange}
                  id="phone"
                  label="Phone Number"
                  error={phoneError} // Pass the error state to the error prop
                  helperText={phoneError ? 'Please use the following format: xxx-xxx-xxxx' : ''} // Display helper text if error is true
                  inputProps={{ maxLength: 15 }}
                />
              </FormControl>
              <FormControl id='size' sx={{ m: 1,}} variant="outlined">
              <TextField
                sx={{
                  width: { sm: '300px', md: '400px', lg: '500px' },
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: theme.palette.secondary.main,
                    },
                  },
                  '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red', // Red border for invalid input
                  },
                }}
                color='secondary'
                id="employees"
                label="Number of Employees"
                value={form.employees}
                onChange={handleChange}
                error={empError} // Pass the error state to the error prop
                helperText={empError ? 'Please enter only numbers' : ''} // Display helper text if error is true
                inputProps={{ maxLength: 4 }}
              />

              </FormControl>
              </div>

              <FormControl  sx={{ m: 1}}>
                <TextField sx={{
                    width: {sm: '620px', md: '820px', lg: '1020px' }, '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: theme.palette.secondary.main,
                      } 
                  }}}
                  id="message"
                  label="Message"
                  value={form.message}
                  onChange={handleChange}
                  color='secondary'
                  inputProps={{ maxLength: 220 }}
                  multiline
                  rows={4}
                  variant="outlined"
                />
              
              <Box>
                <br></br>
                <ReCAPTCHA
                  sitekey="6LftivgpAAAAAFsxAvTEKvQSRve1NrnMmt6-WjpL"
                  onChange={handleCaptcha}
                />
                <br></br>
              </Box>
              
              <div sx={{ m: 10}}>
                {progress ?
              <CircularProgress style={{ color: theme.palette.secondary.main }}/>:
              <Button disabled={!isFormValid} onClick={handleClick} color="secondary" sx={{textTransform: 'none', borderRadius: 2 }} variant='contained'>
                  <Typography component={"div"}>Submit</Typography>
              </Button>
              }
              </div>
              </FormControl>
            </div>
          </Box>
        </Paper>
        
      </Container>

      <Container sx={{p:10}}>
      </Container>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        message="Submission Success" //going to need backend validation befor display
        action={action}
        sx={{
          '& .MuiSnackbarContent-root': {
            backgroundColor: theme.palette.secondary.main, // Change background color
            color: 'white', // Change text color
          },
        }}
      />
    <Anchor/>
    </div>
  );
}