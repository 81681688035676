import {Link} from "react-router-dom";
import * as React from 'react';
import { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useTheme } from '@mui/material/styles';
import { Anchor } from "./Anchor";

import { 
  Typography, 
  Container, 
  Button,
  Box,
  Fade,
  Slide,
  Grid
 } from '@mui/material';
import './App.css';

import wallpaper from './images/asabg.png'
import cyber18 from './images/cyber18.png'
import cyber7 from './images/cyber7.png'
import asa from './images/asa.png'

export function Risk() {

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
    const theme = useTheme()

  const [isExpanded, setIsExpanded] = useState(true);
  const [expanded, setExpanded] = useState(Array(6).fill(false));

  const handleChange = (index) => (event, isExpanded) => {
    setExpanded((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      newExpanded[index] = isExpanded;
      return newExpanded;
    });
  };
  
  
  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  }

  // useEffect(()=>{
  //   window.scrollTo(0, 0);
  // },[])

    return (
      <div>
      <Container maxWidth={false} style={{backgroundImage: `url(${wallpaper})`,backgroundSize: "cover", alignItems: "center", 
      minHeight: "400px", display: 'flex'}} sx={{p: 5}} align="center"  >
        <Slide direction='right' unmountOnExit={true}
          {...{timeout: {enter:1000, exit:1000} }} in={true}> 
          <Container  maxWidth='md' sx={{borderRadius: 2, p: 4}}>
              <Typography component={"div"} align='left' variant='title' color={theme.palette.primary.lightest}  fontWeight='900' > 
                Cyber Risk Quantification
              </Typography>           
          </Container>
        </Slide>
      </Container>

      <Container maxWidth='false'  sx={{padding: {
          xs: '15px', // Padding for extra small screens
          lg: '70px'}}} >
        <Fade unmountOnExit={true} style={{ transformOrigin: '3 3 3', transitionDelay: '0ms' }}
                {...{timeout: {enter:1000, exit:1000} }} in={true}> 
            <Container maxWidth="xl"  style={{ height: "auto"}}  align="center"  >
                <Grid  container direction="row" justifyContent="center" alignItems="center" spacing={10}>
                <Grid item md={6}>
                  <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
                    <img style={{ width: '600px', height: '500px' }} src={cyber18} alt="alternative_text"></img>
                  </Box>
                  <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <img style={{ width: '350px', height: '290px' }}src={cyber18} alt="alternative_text"></img>
                  </Box>
                </Grid>
                <Grid item md={6} align="left">
                    <Typography component={"div"}variant="h1"  color={'primary'} fontWeight={600}>
                    What are Cyber Risk Quantification Services?
                    </Typography><br></br>
                    <Typography component={"div"}color={'primary'}  > 
                    Businesses in this era face an ever-expanding cyber threat landscape they can barely keep up with. 
                    From ransomware to new malware variants, DDoS attacks, APTs to zero-day exploits, etc., IT 
                    professionals have to contend with more threats now than they’ve ever experienced. Thus, 
                    there’s a saturation of cyber risks that continuously test a company’s security posture and resilience, 
                    and with this saturation, the question of where to focus one’s cybersecurity efforts arises. While businesses 
                    can prioritize risks based on their severity and tackle them based on this severity rating, if more than two 
                    risks have the same rating, there’ll be confusion on which risk to tackle first. This limitation is 
                    what ASA's Cyber Risk Quantification aims to fix. Because of the critical influence of cybersecurity 
                    on a business’s growth, reputation, functionality and revenue, it would make more sense to prioritize 
                    risks based on their financial impacts and likelihood of occurrence.
                    {isExpanded? 
                    <div>
                    <Button variant='text' style={{textAlign: 'left', display: 'inline' }} onClick={toggleExpanded} color="secondary">
                        {isExpanded ? 'Read More +' : 'Read Less -'}
                    </Button>
                    </div>: <> <br></br><br></br>In essence, if your company were told it had an impending cyber risk with a 70% chance of occurrence that 
                      could cost it $2 million in losses, you would initiate a DEFCON 1 approach in addressing this risk. This is what ASA's 
                      cyber risk quantification offers, calculated insights into the financial impact of cyber risks to facilitate informed and 
                      timely decisions that keep your business afloat and thriving.<br></br><br></br> Our Cyber Risk Quantification Services are a set of processes 
                      and methodologies used to assess and measure the financial impact and likelihood of cyber threats and vulnerabilities on an 
                      organization's digital assets, operations, and overall business. These services aim to provide organizations with a quantitative 
                      understanding of the risks they face in the digital realm, enabling more informed decision-making, cyber risk management, 
                      and cyber insurance underwriting.</>}
                    </Typography>
                    {!isExpanded? <Button variant='text' style={{textAlign: 'left', display: 'inline' }} onClick={toggleExpanded} color="secondary">
                        {isExpanded ? 'Read More +' : 'Read Less -'}
                      </Button>: ""}
                    
                    {/* <Link to = "/coop" >
                    <Button color="secondary" sx={{width: 120, height: 40, textTransform: 'none', borderRadius: 2 }} variant='contained'>
                        <Typography component={"div"} color={theme.palette.primary.lightest} fontWeight={600} > Learn More</Typography>
                    </Button>
                    </Link> */}
                </Grid>
                </Grid>
            </Container>
          </Fade>
        </Container>

        <Container maxWidth='false' sx={{padding: {
          xs: '15px', // Padding for extra small screens
          lg: '70px'},background: theme.palette.primary.gradient}}>
        <Fade unmountOnExit={true} style={{ transformOrigin: '3 3 3', transitionDelay: '0ms' }}
                {...{timeout: {enter:1000, exit:1000} }} in={true}> 
            <Container maxWidth="xl"  style={{ height: "auto"}}  align="center"  >
                <Grid  container direction="row-reverse" justifyContent="center" alignItems="center" spacing={10}>
                <Grid item md={6}>
                  <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
                    <img style={{ width: '350px', height: '350px' }} src={asa} alt="alternative_text"></img>
                  </Box>
                  <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <img style={{ width: '350px', height: '350px' }} src={asa} alt="alternative_text"></img>
                  </Box>
                </Grid>
                <Grid item md={6} align="left">
                    <Typography component={"div"}variant="h1"  color={'primary'} fontWeight={600}>
                    What is ASA's QRC Offering?
                    </Typography><br></br>
                    <Typography component={"div"}color={'primary'}  > 
                    Cyber Risk Quantification is a series of processes and techniques dedicated to evaluating the cyber risk exposure 
                    and occurrence likelihood of identified cyber risks. These risks are evaluated in monetary terms, as they affect 
                    the enterprise’s measures of financial success. CRQ complements cyber security risk management (CRM) as it translates 
                    a cybersecurity risk into a financial risk. {<br></br>}{<br></br>}Decision makers might not understand 
                    cybersecurity terminology, so the CRQ risk model quantifies the financial impact of a risk event. This way, businessess, IT professionals, stakeholders and those in charge can gain visibility and a deeper understanding of risk impacts and make 
                    better decisions concerning cybersecurity investments.{<br></br>}{<br></br>} Therefore, cyber risk quantification aligns a company’s 
                    security strategy with its business goals. It enables organizations to get the specifics of how cyber risks affect 
                    them and initiates the conversation of cybersecurity posture. CRQ also creates an impact-based 
                    priority list of risks, revealing which risks IT specialists should remediate first.{<br></br>}{<br></br>} 
                    </Typography>
                    </Grid>
                </Grid>
            </Container>
          </Fade>
        </Container>

        <Container maxWidth='false' sx={{padding: {
          xs: '15px', // Padding for extra small screens
          lg: '70px'} }}>
        <Fade unmountOnExit={true} style={{ transformOrigin: '3 3 3', transitionDelay: '0ms' }}
                {...{timeout: {enter:1000, exit:1000} }} in={true}> 
            <Container maxWidth="xl"  style={{ height: "auto"}}  align="center"  >
                <Grid  container direction="row" justifyContent="center" alignItems="center" spacing={10}>
                <Grid item md={6}>
                  <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
                    <img style={{ width: '600px', height: '500px' }} src={cyber7} alt="alternative_text"></img>
                  </Box>
                  <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <img style={{ width: '350px', height: '290px' }}src={cyber7} alt="alternative_text"></img>
                  </Box>
                </Grid>
                <Grid item md={6} align="left">
                    <Typography component={"div"}variant="h1"  color={'primary'} fontWeight={600}>
                    The key components of Cyber Risk Quantification Services include:
                    </Typography><br></br>
                  <Accordion expanded={expanded[0]} onChange={handleChange(0)} align='left' sx={{backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
                    <AccordionSummary
                      sx={{flexDirection: 'row-reverse'}}
                      expandIcon={expanded[0] ? <RemoveCircleOutlineIcon color="secondary"/> : <AddCircleOutlineIcon  color="secondary"/>}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography component={"div"}color={'primary'} variant="h6" fontWeight={900}  marginLeft={2}>Risk Assessment</Typography> 
                    </AccordionSummary>
                    <AccordionDetails sx={{color: theme => theme.palette.primary.main}}>
                    CRQ services begin with a comprehensive assessment of an organization's digital environment. This includes identifying assets, vulnerabilities, and threats. The assessment may involve reviewing security controls, data assets, network architecture, and potential attack vectors.
                    </AccordionDetails>
                  </Accordion >
                  <Accordion expanded={expanded[1]} onChange={handleChange(1)} align='left' sx={{backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
                    <AccordionSummary
                      sx={{flexDirection: 'row-reverse'}}
                      expandIcon={expanded[1] ? <RemoveCircleOutlineIcon color="secondary"/> : <AddCircleOutlineIcon  color="secondary"/>}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                    <Typography component={"div"}color={'primary'} variant="h6" fontWeight={900}  marginLeft={2}> Data Collection </Typography> 
                    </AccordionSummary>
                    <AccordionDetails sx={{color: theme => theme.palette.primary.main}}>
                    To quantify cyber risks, organizations gather data related to their cybersecurity posture, historical breach incidents, and threat 
                    intelligence. This data serves as the foundation for risk analysis.
                    </AccordionDetails>
                  </Accordion >
                  <Accordion expanded={expanded[2]} onChange={handleChange(2)} align='left' sx={{backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
                    <AccordionSummary
                      sx={{flexDirection: 'row-reverse'}}
                      expandIcon={expanded[2] ? <RemoveCircleOutlineIcon color="secondary"/> : <AddCircleOutlineIcon  color="secondary"/>}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                    <Typography component={"div"}color={'primary'} variant="h6" fontWeight={900}  marginLeft={2}> Loss Estimation</Typography> 
                    </AccordionSummary>
                    <AccordionDetails sx={{color: theme => theme.palette.primary.main}}>
                    By quantifying the potential financial losses associated with cyber incidents, organizations can better prioritize their 
                    cybersecurity investments and allocate resources where they are needed most.
                    </AccordionDetails>
                  </Accordion >
                  <Accordion expanded={expanded[3]} onChange={handleChange(3)} align='left' sx={{backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
                    <AccordionSummary
                      sx={{flexDirection: 'row-reverse'}}
                      expandIcon={expanded[3] ? <RemoveCircleOutlineIcon color="secondary"/> : <AddCircleOutlineIcon  color="secondary"/>}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                    <Typography component={"div"}color={'primary'} variant="h6" fontWeight={900}  marginLeft={2}> Risk Mitigation</Typography> 
                    </AccordionSummary>
                    <AccordionDetails sx={{color: theme => theme.palette.primary.main}}>
                    Once an organization has a clearer understanding of its cyber risks, it can develop and implement risk mitigation strategies. 
                    This may include investing in improved cybersecurity measures, developing incident response plans, and purchasing cyber insurance.
                    </AccordionDetails>
                  </Accordion >
                  <Accordion expanded={expanded[4]} onChange={handleChange(4)} align='left' sx={{backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
                    <AccordionSummary
                      sx={{flexDirection: 'row-reverse'}}
                      expandIcon={expanded[4] ? <RemoveCircleOutlineIcon color="secondary"/> : <AddCircleOutlineIcon  color="secondary"/>}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                    <Typography component={"div"}color={'primary'} variant="h6" fontWeight={900}  marginLeft={2}> Decision Support</Typography> 
                    </AccordionSummary>
                    <AccordionDetails sx={{color: theme => theme.palette.primary.main}}>
                    By quantifying cyber risks, organizations can make more informed decisions regarding their cybersecurity investments. 
                    They can prioritize resources based on the potential impact of different cyber threats and vulnerabilities.
                    </AccordionDetails>
                  </Accordion >
                </Grid>
                </Grid>
            </Container>
          </Fade>
        </Container>

        <Container maxWidth={false} sx={{padding: {
          xs: '15px', // Padding for extra small screens
          lg: '70px'}, background: theme.palette.primary.gradient}}>
        
        <Grid  container direction="row" justifyContent="center" alignItems="center" spacing={5}>
          <Grid item md={10} align="left">
            <Typography component={"div"}variant="h1"   color={'primary'} fontWeight={800}>
              Conclusion
            </Typography><br></br>
            <Typography component={"div"}  sx={{color: theme => theme.palette.primary.main}}  > 
            ASA's cyber risk quantification solution helps businesses prioritize the remediation 
            of risks based on their impact. It also offers insights to stakeholders with which they can 
            influence their security posture by justifying security investments, improving understanding and 
            making better holistic cybersecurity decisions, which can only be beneficial to the credibility, 
            reputation, finances and continuity of your business.
            </Typography>
            <br></br>
            <Link to = "/contact" >
              <Button color="secondary" sx={{width: 180, height: 40, textTransform: 'none', borderRadius: 2 }} variant='contained'>
                <Typography component={"div"} color={theme.palette.primary.lightest} fontWeight={600} > Talk to an expert</Typography>
              </Button>
            </Link>
            <br></br><br></br>
            <br></br>
          </Grid>
        </Grid>
      </Container>
      <Anchor/>
      </div>
      )}




















        