import {Link} from "react-router-dom";
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Anchor } from "./Anchor";
import { useTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import { 
  Typography, 
  Container, 
  Button,
  Box,
  Fade,
  Slide,
  Grid
 } from '@mui/material';
import './App.css';
import cyber15 from './images/cyber15.png'
import wallpaper from './images/asabg.png'

export function Training() {

  const [expanded, setExpanded] = useState(Array(5).fill(false));

  const handleChange = (index) => (event, isExpanded) => {
    setExpanded((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      newExpanded[index] = isExpanded;
      return newExpanded;
    });
  };

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  const theme = useTheme()
  
    return (
      <div>
      <Container maxWidth={false} style={{backgroundImage: `url(${wallpaper})`,backgroundSize: "cover", alignItems: "center", 
      minHeight: "400px", display: 'flex'}} sx={{p: 5}} align="center"  >
        <Slide direction='right' unmountOnExit={true}
          {...{timeout: {enter:1000, exit:1000} }} in={true}> 
          <Container  maxWidth='md' sx={{borderRadius: 2, p: 4}}>
              <Typography component={"div"} align='left' variant='title' color={theme.palette.primary.lightest}  fontWeight='900' > 
              Training and Awareness
              </Typography>           
          </Container>
        </Slide>
      </Container>

      <Container maxWidth='false'  sx={{padding: {
          xs: '15px', // Padding for extra small screens
          lg: '70px'}}} >
        <Fade unmountOnExit={true} style={{ transformOrigin: '3 3 3', transitionDelay: '0ms' }}
                {...{timeout: {enter:1000, exit:1000} }} in={true}> 
            <Container maxWidth="xl"  style={{ height: "auto"}}  align="center"  >
                <Grid  container direction="row" justifyContent="center" alignItems="center" spacing={10}>
               
                <Grid item md={6} align="left">
                <Typography component={"div"}variant="h3"  color={'secondary'} fontWeight={600}>
                Cybersecurity Awareness Training
              </Typography><br></br>
              <Typography component={"div"}variant="h1"   color={'primary'} fontWeight={900}>
              Cyber attacks are rising, with attackers becoming increasingly sophisticated, looking for ways to hack businesses and steal customers' information. 
              </Typography><br></br>
              <Typography component={"div"} color={'gray'} >
                Businesses must look for ways to protect themselves against future attacks using state-of-the-art cybersecurity tools to keep attackers off.
                But that is not enough because technology alone does not provide foolproof protection from attackers and data breaches. So, organizations must 
                leverage cybersecurity awareness and train their employees on how to protect confidential information from attackers. Our security awareness training
                program helps educate your employees and users and empowers them to detect and avoid common online threats consistently.
              </Typography>

                </Grid>
                <Grid item md={6}>
                  <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
                    <img style={{ width: '500px', height: '500px' }} src={cyber15} alt="alternative_text"></img>
                  </Box>
                  <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <img style={{ width: '350px', height: '350px' }}src={cyber15} alt="alternative_text"></img>
                  </Box>
                </Grid>
                </Grid>
            </Container>
          </Fade>
        </Container>

        <Container  maxWidth={false} style={{ height: "auto"}}  sx={{padding: {
          xs: '15px', // Padding for extra small screens
          lg: '70px'} ,background: theme.palette.primary.gradient}}>
         <Grid  container direction="row" justifyContent="center" alignItems="center" spacing={5}>
         <Grid direction={'row-reverse'} item md={5} align="left">
          <Typography component={"div"}variant="h1"   color={'primary'} fontWeight={900}>
          What A Good Security Awareness Training Should Include
          </Typography><br></br>
          <Typography component={"div"} color={'primary'} >
          A good cybersecurity awareness training program should be multifaceted, with a collection of lessons and 
          learning opportunities to engage employees in your organization. Also, a comprehensive security program should 
          be role-based, delivering instructional materials that meet the needs of your employees to ensure anyone doesn't 
          put the organization at risk.
          </Typography>
        </Grid>
          <Grid item md={5}>
            <Accordion expanded={expanded[0]} onChange={handleChange(0)} align='left' sx={{backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
              <AccordionSummary
                sx={{flexDirection: 'row-reverse'}}
                expandIcon={expanded[0] ? <RemoveCircleOutlineIcon color="secondary"/> : <AddCircleOutlineIcon  color="secondary"/>}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography component={"div"}color={'primary'} variant="h6" fontWeight={900}  marginLeft={2}>Phishing Attacks</Typography> 
              </AccordionSummary>
              <AccordionDetails sx={{color: theme => theme.palette.primary.main}}>
              Phishing is social engineering, where cyber attackers send fraudulent emails to unsuspecting users to trick them into 
              revealing confidential information. Your employees need constant training to spot a phishing attack and report it to avoid 
              being targeted. Real-world simulated phishing attacks will test awareness and reinforce proper practices at the point of click.
              </AccordionDetails>
            </Accordion >
            <Accordion expanded={expanded[1]} onChange={handleChange(1)} align='left' sx={{backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
              <AccordionSummary
                sx={{flexDirection: 'row-reverse'}}
                expandIcon={expanded[1] ? <RemoveCircleOutlineIcon color="secondary"/> : <AddCircleOutlineIcon  color="secondary"/>}
                aria-controls="panel1-content"
                id="panel1-header"
              >
               <Typography component={"div"}color={'primary'} variant="h6" fontWeight={900}  marginLeft={2}> Password and Authentication </Typography> 
              </AccordionSummary>
              <AccordionDetails sx={{color: theme => theme.palette.primary.main}}>
              Passwords are essential in keeping user authentication and preventing attackers from stealing your data. Your employees should 
              understand how to create difficult-to-guess passwords to secure their accounts. Also, they should understand the risk of 
              using the same password for personal and corporate accounts.
              </AccordionDetails>
            </Accordion >
            <Accordion expanded={expanded[2]} onChange={handleChange(2)} align='left' sx={{backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
              <AccordionSummary
                sx={{flexDirection: 'row-reverse'}}
                expandIcon={expanded[2] ? <RemoveCircleOutlineIcon color="secondary"/> : <AddCircleOutlineIcon  color="secondary"/>}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography component={"div"}color={'primary'} variant="h6" fontWeight={900} marginLeft={2}>Ransomware</Typography> 
              </AccordionSummary>
              <AccordionDetails sx={{color: theme => theme.palette.primary.main}}>
              Ransomware is another way cybercriminals can attack your organization. It involves using malicious software that encrypts data on your 
              computer until a sum of money (ransom) is paid to the attackers. Your employees must be aware that ransomware is one of the threats attackers 
              use to target businesses and prevent it from not happening in the first place.
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded[3]} onChange={handleChange(3)} align='left' sx={{backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
              <AccordionSummary
                sx={{flexDirection: 'row-reverse'}}
                expandIcon={expanded[3] ? <RemoveCircleOutlineIcon color="secondary"/> : <AddCircleOutlineIcon  color="secondary"/>}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography component={"div"}color={'primary'} variant="h6" fontWeight={900}  marginLeft={2}> Information Security</Typography> 
              </AccordionSummary>
              <AccordionDetails sx={{color: theme => theme.palette.primary.main}}>
              Information security for organizations is the practice of protecting digital assets from cyber attackers. Since attackers are trying to steal 
              your organization's data, employees should be careful when sharing sensitive information online. Also, they should know your organization's 
              security policies and take sensitive data very seriously.
              </AccordionDetails>
            </Accordion >
            <Accordion expanded={expanded[4]} onChange={handleChange(4)} align='left' sx={{backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
              <AccordionSummary
                sx={{flexDirection: 'row-reverse'}}
                expandIcon={expanded[4] ? <RemoveCircleOutlineIcon color="secondary"/> : <AddCircleOutlineIcon  color="secondary"/>}
                aria-controls="panel1-content"
                id="panel1-header"
              >
               <Typography component={"div"}color={'primary'} variant="h6" fontWeight={900}  marginLeft={2}> Removable Media</Typography> 
              </AccordionSummary>
              <AccordionDetails sx={{color: theme => theme.palette.primary.main}}>
              Another cybersecurity awareness topic is removable media, which can be a significant risk factor for cyberattacks if not taken seriously. 
              Your employees should know how to protect sensitive information stored in USB drives, external hard drives and other portable devices. Also, 
              they must be cautious when plugging external drives into your organization's system.
              </AccordionDetails>
            </Accordion >
            <Accordion expanded={expanded[5]} onChange={handleChange(5)} align='left' sx={{backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
              <AccordionSummary
                sx={{flexDirection: 'row-reverse'}}
                expandIcon={expanded[5] ? <RemoveCircleOutlineIcon color="secondary"/> : <AddCircleOutlineIcon  color="secondary"/>}
                aria-controls="panel1-content"
                id="panel1-header"
              >
               <Typography component={"div"}color={'primary'} variant="h6" fontWeight={900}  marginLeft={2}> Cloud Security</Typography> 
              </AccordionSummary>
              <AccordionDetails sx={{color: theme => theme.palette.primary.main}}>
              Cloud security refers to the technologies, controls, policies and services put in place to protect data, applications and infrastructure in 
              the cloud from cyber criminals. Security awareness training will help to guide your employees through the secure use of cloud base applications.
              </AccordionDetails>
            </Accordion >
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth={false}  sx={{padding: {
          xs: '15px', // Padding for extra small screens
          lg: '70px'}}} >
        <Grid  container direction="row" justifyContent="center" alignItems="center" spacing={5}>
          <Grid item md={10} align="left">
            <Typography component={"div"}variant="h1"   color={'primary'} fontWeight={800}>
              Let us teach you how to win against cybercriminals
            </Typography><br></br>
            <Typography component={"div"}  sx={{color: theme => theme.palette.primary.main}}  > 
            At ASA we take a holistic approach to addressing cybersecurity issues. 
            We understand that threats and technologies are constantly changing, so we tailor 
            our security awareness training to suit your organization's needs. Our cybersecurity 
            awareness training effectively changes your employees' mindset toward cultivating a 
            secure work culture to prevent future attacks.
            </Typography>
            <br></br>
            <Link to = "/contact" >
              <Button color="secondary" sx={{width: 180, height: 40, textTransform: 'none', borderRadius: 2 }} variant='contained'>
                <Typography component={"div"} color={theme.palette.primary.lightest} fontWeight={600} > Talk to an expert</Typography>
              </Button>
            </Link>
            <br></br><br></br>
            <br></br>
          </Grid>
        </Grid>
      </Container>
    <Anchor/>
  </div>
    );
  }
  


