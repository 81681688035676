import {Link} from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { Anchor } from "./Anchor";
import { 
  Typography, 
  Container, 
  Button,
  Box,
  Fade,
  Slide,
  Grid
 } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import './App.css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import cyber19 from './images/cyber19.png'
import cyber20 from './images/cyber20.png'
import wallpaper from './images/asabg.png'


export function Continuity() {

  const theme = useTheme()

  const [expanded, setExpanded] = useState(Array(4).fill(false));
  const [expanded1, setExpanded1] = useState(true);

  const handleChange = (index) => (event, isExpanded) => {
    setExpanded((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      newExpanded[index] = isExpanded;
      return newExpanded;
    });
  };

  const handleChange1 = () =>{
    setExpanded1(!expanded1)
  };


  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  
    return (
      <div>
      <Container maxWidth={false} style={{backgroundImage: `url(${wallpaper})`,backgroundSize: "cover", alignItems: "center", 
      minHeight: "400px", display: 'flex'}} sx={{p: 5}} align="center"  >
        <Slide direction='right' unmountOnExit={true}
          {...{timeout: {enter:1000, exit:1000} }} in={true}> 
          <Container  maxWidth='md' sx={{borderRadius: 2, p: 4}}>
              <Typography component={"div"} align='left' variant='title' color={theme.palette.primary.lightest}  fontWeight='900' > 
              Incident Response Planning
              </Typography>           
          </Container>
        </Slide>
      </Container>

        <Container maxWidth='false' sx={{padding: {
          xs: '12px', // Padding for extra small screens
          lg: '60px'}}}>
        <Fade unmountOnExit={true} style={{ transformOrigin: '3 3 3', transitionDelay: '0ms' }}
              {...{timeout: {enter:1000, exit:1000} }} in={true}> 
            <Container maxWidth="xl"  style={{ height: "auto"}}  align="center"  >
              <Grid  container direction="row"alignItems="center" spacing={10}>
                
                <Grid item md={6} align="left">
                <Typography component={"div"}variant="h3"  color={'secondary'} fontWeight={800}>
                  Why Do You Need Incident Response and Continuity Plans?
                  </Typography><br></br>
                  <Typography component={"div"}variant="h1"  color={'primary'} fontWeight={800}>
                  Because when disaster strikes, it should only be an inconvenience
                  </Typography><br></br>
                  <Typography component={"div"} color={'primary'}  > 
                  Protecting your business is not just about
                  defense, but also resilience. In an ever-evolving digital world, continuity planning and 
                  development are imperative. We can help you develop effective Incident Response, 
                  Disaster Recovery, and Continuity of Operations plans. Our services are meticulously crafted to fortify your organization, 
                  guaranteeing that essential functions continue seamlessly, even during crises. Trust us to help plan 
                  your continuity in the most challenging of cyber landscapes.
                  </Typography><br></br>
                 
                  <br></br>
                </Grid>
                <Grid item md={6} >
                 <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
                    <img style={{ width: '600px', height: '470px' }} src={cyber20} alt="alternative_text"></img>
                  </Box>
                  <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <img style={{ width: '300px', height: '300px' }}src={cyber20} alt="alternative_text"></img>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Fade>
        </Container>

        <Container maxWidth={false} style={{ height: "auto"}}  sx={{padding: {
          xs: '15px', // Padding for extra small screens
          lg: '70px'} ,background: theme.palette.primary.gradient}}>
         <Grid  container direction="row" justifyContent="center" alignItems="top" spacing={5}>
          <Grid item md={5} align="center">
            <Typography component={"div"}align="left" color='primary' fontWeight={800} variant="h1">We'll Help You Develop:</Typography><br></br><br></br>
            <Box align="left"  sx={{ display: { sm: 'block', xs: 'none' } }}>
              <img style={{ width: '350px', height: '290px' }} src={cyber19} alt="alternative_text"></img>
            </Box>
            <Box align="left"  sx={{ display: { xs: 'block', sm: 'none' } }}>
              <img style={{ width: '350px', height: '290px' }}src={cyber19} alt="alternative_text"></img>
            </Box>
          </Grid>
          <Grid  item md={5}>
            <Accordion  defaultExpanded={true} onChange={handleChange1} align='left' sx={{backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
              <AccordionSummary
                sx={{flexDirection: 'row-reverse'}}
                expandIcon={expanded1 ? <RemoveCircleOutlineIcon color="secondary"/> : <AddCircleOutlineIcon  color="secondary"/>}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography component={"div"}color={'primary'} variant="h6" fontWeight={900}  marginLeft={2}>Incident Response Plan</Typography> 
              </AccordionSummary>
              <AccordionDetails sx={{color: theme => theme.palette.primary.main}}>
              An Incident Response (IR) plan outlines procedures for detecting, responding to, and recovering from 
              cybersecurity incidents or emergencies. It includes steps for identifying threats, containing breaches, 
              mitigating damage, and restoring systems to minimize impact on operations and data security.
              <br></br><br></br>
              We'll help you develop and refinine IR plans ensuring readiness for unforeseen crises. 
              Through tailored guidance and expertise, we navigate clients through the process of identifying risks, establishing 
              procedures, and crafting comprehensive strategies to mitigate impact, safeguard business continuity, and ensure regulatory compliance.
              </AccordionDetails>
            </Accordion >
            <Accordion expanded={expanded[1]} onChange={handleChange(1)} align='left' sx={{backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
              <AccordionSummary
                sx={{flexDirection: 'row-reverse'}}
                expandIcon={expanded[1] ? <RemoveCircleOutlineIcon color="secondary"/> : <AddCircleOutlineIcon  color="secondary"/>}
                aria-controls="panel1-content"
                id="panel1-header"
              >
               <Typography component={"div"}color={'primary'} variant="h6" fontWeight={900}  marginLeft={2}> Disaster Recovery Plan </Typography> 
              </AccordionSummary>
              <AccordionDetails sx={{color: theme => theme.palette.primary.main}}>
              A Disaster Recovery Plan (DRP) is a structured approach to restoring IT infrastructure and data after a catastrophic event. 
              It includes procedures for backing up data, recovering systems, and resuming critical business functions, aiming to 
              minimize downtime and mitigate financial losses.
              <br></br><br></br>
              We'll help craft robust DRPs, ensuring resilience in the face of 
              catastrophic events. Our approach involves assessing vulnerabilities, implementing recovery procedures, and 
              orchestrating protocols and procedures to swiftly restore critical operations, minimizing disruptions and 
              getting your business up and running as quickly as possible.
             
              </AccordionDetails>
            </Accordion >
            <Accordion expanded={expanded[2]} onChange={handleChange(2)} align='left' sx={{backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
              <AccordionSummary
                sx={{flexDirection: 'row-reverse'}}
                expandIcon={expanded[2] ? <RemoveCircleOutlineIcon color="secondary"/> : <AddCircleOutlineIcon  color="secondary"/>}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography component={"div"}color={'primary'} variant="h6" fontWeight={900} marginLeft={2}>Continuity of Operations Plan</Typography> 
              </AccordionSummary>
              <AccordionDetails sx={{color: theme => theme.palette.primary.main}}>
              A Business Continuity Plan (COOP) focuses on sustaining essential business functions during and after disruptions, 
              ensuring continuity of operations. Unlike IR plans, which address immediate threats, and DRPs, which focus on IT recovery, 
              COOP plans encompass broader strategies for maintaining business 
              operations across various scenarios, safeguarding against prolonged downtime and preserving overall business resilience.
              <br></br><br></br>
              We partner with small businesses to develop robust business COOP plans, ensuring operational 
              resilience amid challenges. We will identify critical functions, devise proactive 
              strategies, and implement procedures to sustain operations during disruptions. By prioritizing preparedness, 
              we safeguard continuity and bolster business stability.
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth={false}  sx={{padding: {
          xs: '15px', // Padding for extra small screens
          lg: '70px'}}} >
        <Grid  container direction="row" justifyContent="center" alignItems="center" spacing={5}>
          <Grid item md={10} align="left">
            <Typography component={"div"}variant="h1"   color={'primary'} fontWeight={800}>
             We'll Take Care of You.
            </Typography><br></br>
            <Typography component={"div"}  sx={{color: theme => theme.palette.primary.main}}  > 
            Whether for regulatory compliance, business expansion, or just peace of mind, when you choose ASA to assist with your 
            business COOP, IR, and DRP planning, you will receive a custom solution that addresses all your concerns. Our expert 
            team collaborates closely with you to assess risks, design tailored strategies, and implement comprehensive plans, 
            ensuring your business remains resilient in the face of any challenge.
            </Typography>
            <br></br>
            <Link to = "/contact" >
              <Button color="secondary" sx={{width: 180, height: 40, textTransform: 'none', borderRadius: 2 }} variant='contained'>
                <Typography component={"div"} color={theme.palette.primary.lightest} fontWeight={600} > Talk to an expert</Typography>
              </Button>
            </Link>
            <br></br><br></br>
            <br></br>
          </Grid>
        </Grid>
      </Container>
    <Anchor/>
  </div>
    );
  }
  

