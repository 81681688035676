import React, {useState, useEffect } from 'react';
import { Anchor } from "./Anchor"
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"
import Divider from '@mui/material/Divider';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './slick-overrides.css';
import { 
  Typography, 
  Container, 
  Box,
  Paper,
  Grid
 } from '@mui/material';
 import wallpaper from './images/asabg.png'
 import values from './images/values.png'
 import omaha from './images/skyline.jpg'
import { useTheme } from '@mui/material/styles';
import bioPhoto from './images/bioPhoto.jpg'
import bioPhoto2 from './images/bioPhoto2.jpg'

export function Asa() {

  const theme = useTheme()

  const [isExpanded, setIsExpanded] = useState();

  useEffect(()=>{
    window.scrollTo(0, 0);
    setIsExpanded(true)
  },[])

  return (
    <div>
      
    <Container maxWidth={false} style={{
      backgroundImage: `url(${wallpaper})`,
      backgroundSize: 'cover',
      alignItems: 'center',
      justifyContent: 'center', // Center horizontally
      flexDirection: 'column', 
      minHeight: "400px", display: 'flex'}} align="center"  >
      {isExpanded?
      <ScrollAnimation initiallyVisible animateOnce animateIn="pulse">
        <Container  sx={{borderRadius: 2, p: 3}}>
            <Typography component={"div"} align='left' variant='title' color={theme.palette.primary.lightest}  fontWeight='900' > 
            We go the distance with expertise, integrity, and personal committment
            </Typography>           
        </Container>
      </ScrollAnimation>
      :""}
       </Container>
   
      <Container align="left" sx={{padding: {
          xs: '20px', // Padding for extra small screens
          sm: '15px', // Padding for small screens
          md: '20px', // Padding for medium screens
          lg: '80px'} }}>
        <Container>
          <Typography component={"div"}variant='h1' fontWeight={700}>
          Welcome to ASA Cybersecurity Consulting
          </Typography> 
      
          <Typography component={"div"}color={'secondary'} variant='h3'fontWeight={700} >
          Proudly Serving Omaha 
          </Typography> 
          <br></br>
          <Typography component={"div"}textAlign={'left'} >
          ASA is your trusted partner in navigating the complexities of the digital world we live in. 
          From ensuring regulatory compliance to implementing robust technical controls against cyber threats, ASA Consulting 
          is dedicated to guiding you every step of the way. We specialize in keeping your networks secure and compliant with regulations, 
          while <strong>guaranteeing customer satisfaction</strong> along the way.
          </Typography>
        </Container>
      </Container>

      <Container maxWidth={false} style={{backgroundImage: `url(${omaha})`, backgroundPosition: 'center', backgroundSize: "cover", alignItems: "center", 
      minHeight: "400px", display: 'flex'}} sx={{p: 5}} align="center"  >
      </Container>
    
      <Container align="center" sx={{padding: {
          xs: '10px', // Padding for extra small screens
          sm: '15px', // Padding for small screens
          md: '20px', // Padding for medium screens
          lg: '80px'} }}>
        <Grid  container direction="column" justifyContent="center" alignItems="center" spacing={5}>
          <Grid  item md={6} align="center">
            <Typography component={"div"}fontWeight={700} variant='h1'>
              Our Values
            </Typography>
          </Grid>
          <Grid item md={6} align="center">
          <ScrollAnimation duration={2} animateOnce animateIn="tada">
          <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
            <img style={{ width: '100px', height: '100px' }} src={values} alt="alternative_text"></img>
          </Box>
          <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
            <img style={{ width: '100px', height: '100px' }}src={values} alt="alternative_text"></img>
          </Box>
          </ScrollAnimation>
          </Grid>
          <Grid  item md={6} align="center">
            <Typography textAlign={'left'} variant='h3' component={"div"} >
              As a veteran-owned company, we operate by the same core values as the service: integrity first, service before self, 
              and excellence in all we do. At ASA, we have an unwavering committment to our customers, fully dedicated to excellency in an honest and transparent
              manner.
            </Typography>
          </Grid>
        </Grid>
        <br></br>
        <br></br>

        <Grid  container direction="row" justifyContent="center" alignItems="top" spacing={15}>
          <Grid  item lg={4} align="center">
          <ScrollAnimation duration={2} animateOnce animateIn="slideInLeft">
            <Paper  elevation={5} sx={{background: '0px 4px 20px rgba(112,66,252,.64)' ,width: {xs: '350px'}, borderRadius: 7, boxShadow: '0px 4px 25px rgba(31,83,255,.70)', p: 5}}  
            style={{ overflow: 'auto', wordWrap: 'break-word' }}>
              <Typography component={"div"}sx={{p:1}} fontWeight={700} variant='h2'>Integrity</Typography>
              <Divider style={{backgroundColor:'rgba(31,83,255)', width: '75px', height: '2px' }} />
              <br></br>
              <Typography component={"div"}sx={{p:1}}>
              Upholding integrity isn't just a promise; it's a commitment woven into the very fabric of our business. It means 
              that every action we take is guided by honesty, transparency, and respect for our customers. We understand that 
              trust is the foundation of any successful relationship, and we strive to earn and maintain that trust every day.
              </Typography>
            </Paper>
            </ScrollAnimation>
          </Grid>
          <Grid item lg={4} align="center">
          <ScrollAnimation duration={2} animateOnce animateIn="slideInUp">
            <Paper  elevation={5} sx={{background: '0px 4px 20px rgba(112,66,252,.64)', width: {xs: '350px'}, borderRadius: 7, boxShadow: '0px 4px 25px rgba(112,66,252,.70)', p: 5}}  
              style={{ overflow: 'auto', wordWrap: 'break-word' }}>
              <Typography component={"div"}sx={{p:1}} fontWeight={700} variant='h2'>Service</Typography>
              <Divider style={{backgroundColor: theme.palette.secondary.main, width: '75px', height: '2px' }} />
              <br></br>
              <Typography component={"div"}sx={{p:1}}>
                Our definition of "service" has shifted from "service to our country," to "service to our clients." our clients are at the core of 
                everything we do, and their needs, preferences, and feedback shape our every action. 
                From the moment a client engages with us, whether it's through inquiry, purchase, or support, our goal is to ensure 
                they feel valued, heard, and ultimately delighted by their experience.
              </Typography>
            </Paper>
            </ScrollAnimation>
          </Grid>
          <Grid  item lg={4} align="center">
          <ScrollAnimation animateOnce animateIn="slideInRight">
            <Paper  elevation={5} sx={{background: '0px 4px 20px rgba(112,66,252,.64)', width: {xs: '350px'}, borderRadius: 7, boxShadow: '0px 4px 25px rgba(2,161,25,.70)', p: 5}}  
              style={{ overflow: 'auto', wordWrap: 'break-word' }}>
              <Typography component={"div"}sx={{p:1}} fontWeight={700} variant='h2'>Excellence</Typography>
              <Divider style={{backgroundColor: 'rgba(2,161,25)', width: '75px', height: '2px' }} />
              <br></br>
              <Typography component={"div"}sx={{p:1}}>
                At the heart of our ethos lies a commitment to excellence in every facet of our operations. It's not just about 
                achieving success; it's about consistently surpassing it. We understand that excellence isn't a destination, but a 
                journey, and we are dedicated to continually raising the bar and pushing the boundaries of what's possible.
              </Typography>
            </Paper>
            </ScrollAnimation>
          </Grid>
        </Grid>
      </Container>
      <br></br>

      <Divider variant='middle' style={{backgroundColor: theme.palette.secondary.main }} />
      <Container align="center" sx={{padding: {
          xs: '54px', // Padding for extra small screens
          sm: '15px', // Padding for small screens
          md: '20px', // Padding for medium screens
          lg: '40px'} }}>
        <Container sx={{p: 5}}>
          <Typography component={"div"}sx={{p: 2}} fontWeight={700} variant='h1'>
            Company Leadership
          </Typography>
          <Divider style={{backgroundColor: theme.palette.secondary.main, width: '75px', height: '2px' }} />
        </Container>
          <Grid  container direction="row" alignItems="top" spacing={5}>
            <Grid justifyContent={'center'} item lg={6} ><br></br>
              <Paper align="center" elevation={5} sx={{backgroundPosition: 'center', height: '85%', background: `url(${bioPhoto})`, backgroundSize: "cover", boxShadow: '0px 4px 20px '}}>
                <Box align="center" sx={{ display: { sm: 'none', xs: 'block' } }} style={{ width: '300px', height: '500px' }}>
                </Box>
                <Box align="center" sx={{ display: { sm: 'block', xs: 'none' } }} style={{ width: '580px', height: '700px' }}>
                </Box>
              </Paper>
            </Grid>
            <Grid align='left' item lg={6} >
              <Typography component={"div"}variant="h2" fontWeight={600}>
              Antonio R. Daehler
              </Typography>
              <Typography component={"div"}variant="h3" fontWeight={600}>
              Owner, Co-founder, and Lead Consultant
              </Typography><br></br>
              <Typography component={"div"}  color={'gray'}>
              Meet Tony, a seasoned professional, Navy Veteran and Air Force Officer with a diverse background in IT and cybersecurity. With a passion for technological
              innovation and a commitment to excellence, Tony has spearheaded numerous critical projects,
              leaving an indelible mark on the landscape of military IT infrastructure.<br></br><br></br>
              Tony embarked on his journey in the United States Air Force, where he was instrumental in the development of an enterprise IT system tailored for 
              the United States Space Force (USSF).
                As an Information Systems Security Manager (ISSM), Tony meticulously safeguarded some of the most sensitive data on earth and
                fortified defenses against nation-state cyber threats, ensuring the confidentiality, integrity and availability of mission-critical systems.<br></br><br></br>
              Tony has enhanced his practical experience with a solid academic foundation, earning a Bachelor of Science 
              in Business Administration from the University of Arizona in 2018, and a Master of Science in Information Systems from the University of 
              Utah in 2020, with a specialization in cybersecurity. Tony also achieved the prestigious Certified Information Systems Security Professional (CISSP) certification in 2020.<br></br><br></br>
              Driven by a relentless pursuit of excellence and a deep-rooted commitment to national security,
              Tony continues to make invaluable contributions to the realms of IT and cybersecurity, embodying the 
              Air Force's core values of integrity, service, and excellence in all endeavors.
              </Typography>
              <br></br><br></br>
              <br></br><br></br>
            </Grid>
            </Grid>
            <Divider style={{backgroundColor: theme.palette.secondary.main }} />
            <br></br><br></br>
            <br></br><br></br>
            <Grid  container direction="row-reverse" alignItems="top" spacing={5}>
              <Grid justifyContent={'center'} item lg={6} ><br></br>
                <Paper align="center" elevation={5} sx={{backgroundPosition: 'center', height: '85%', background: `url(${bioPhoto2})`, backgroundSize: "cover", boxShadow: '0px 4px 20px '}}>
                  <Box align="center" sx={{ display: { sm: 'none', xs: 'block' } }} style={{ width: '300px', height: '500px' }}>
                  </Box>
                  <Box align="center" sx={{ display: { sm: 'block', xs: 'none' } }} style={{ width: '580px', height: '700px' }}>
                  </Box>
                </Paper>
              </Grid>
              <Grid align='left' item lg={6} >
                <Typography component={"div"}variant="h2" fontWeight={600}>
                Allen T. Burns
                </Typography>
                <Typography component={"div"}variant="h3" fontWeight={600}>
                Owner and Co-founder
                </Typography><br></br>
                <Typography component={"div"}  color={'gray'}>
                Meet Allen, a former Technician and Supervisor at Texas Instruments and a proud veteran of the United States Navy, 
                specializing in flash memory hardware. With a strong focus on technological innovation, he has led key projects that 
                enhance the security and efficiency of critical systems within the photolithography department. 
                <br></br><br></br>
                Allen began his 
                journey in the Navy as nuclear reactor technician, where he developed robust technical skills in physics and engineering 
                and gained experience in safeguarding extremely sensitive 
                information. At Texas Instruments, he spearheaded the implementation of automated monitoring systems, improving not 
                only operational efficiency but also enhancing data integrity and security.
                <br></br><br></br>
                Prior to his role at Texas Instruments, 
                Allen served as a Team Supervisor at Micron Technology, where he oversaw photolithography operations and collaborated with 
                cross-functional teams to streamline processes. He played a pivotal role in revamping documentation standards, 
                ensuring clarity in procedures critical for maintaining compliance and security. 
                <br></br><br></br>
                Passionate about personal and 
                team development, Allen is committed to fostering a culture of continuous learning within his team. With expertise 
                in failure analysis, manufacturing engineering, and cybersecurity practices, he is dedicated to mitigating risks 
                and enhancing the security posture of the organization, embodying the core values of integrity and excellence in 
                all endeavors.
                </Typography>
                <br></br><br></br>
                <br></br><br></br>
              </Grid>
            </Grid>
          </Container>
        <Anchor/>
    </div>
  );
}
