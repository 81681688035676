import {Link} from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { Anchor } from "./Anchor";
import { 
  Typography, 
  Container, 
  Button,
  Box,
  Fade,
  Slide,
  Grid
 } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import './App.css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import cyber10 from './images/cyber10.png'
import cyber16 from './images/cyber16.png'
import cyber21 from './images/cyber21.png'
import cyber22 from './images/cyber22.png'
import wallpaper from './images/asabg.png'

export function Compliance() {

  const [expanded, setExpanded] = useState(Array(5).fill(false));
  const [expanded1, setExpanded1] = useState(true);

  const handleChange = (index) => (event, isExpanded) => {
    setExpanded((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      newExpanded[index] = isExpanded;
      return newExpanded;
    });
  };

  const handleChange1 = () =>{
    setExpanded1(!expanded1)
  };

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  const theme = useTheme()
  
    return (
      <div>
      <Container maxWidth={false} style={{backgroundImage: `url(${wallpaper})`,backgroundSize: "cover", alignItems: "center", 
      minHeight: "400px", display: 'flex'}} sx={{p: 5}} align="center"  >
        <Slide direction='right' unmountOnExit={true}
          {...{timeout: {enter:1000, exit:1000} }} in={true}> 
          <Container  maxWidth='md' sx={{borderRadius: 2, p: 4}}>
              <Typography component={"div"} align='left' variant='title' color={theme.palette.primary.lightest}  fontWeight='900' > 
              Regulatory Compliance
              </Typography>           
          </Container>
        </Slide>
      </Container>

      <Container maxWidth='false'  sx={{padding: {
          xs: '15px', // Padding for extra small screens
          lg: '70px'}}} >
        <Fade unmountOnExit={true} style={{ transformOrigin: '3 3 3', transitionDelay: '0ms' }}
                {...{timeout: {enter:1000, exit:1000} }} in={true}> 
            <Container maxWidth="xl"  style={{ height: "auto"}}  align="center"  >
                <Grid  container direction="row" justifyContent="center" alignItems="center" spacing={10}>
                <Grid item md={5} align="left">
                    <Typography component={"div"}variant="h1" color={'primary'} fontWeight={800}>
                    HIPAA 
                    </Typography><br></br>
                    <Typography component={"div"}  color={'primary'}  > 
                    Navigating the intricacies of the HIPAA Security Rule on your own can 
                    be daunting. This regulation outlines specific requirements for safeguarding electronic protected 
                    health information (ePHI), imposing stringent standards on healthcare organizations to ensure data 
                    security and confidentiality. Without expert guidance, understanding the nuances of the Security Rule 
                    and implementing compliant measures can be overwhelming. 
                    Our experienced team is well-versed in the intricacies of the Security Rule and provides tailored 
                    solutions to help your organization achieve and maintain compliance. From conducting thorough risk 
                    assessments to implementing robust technical safeguards, we guide you through every step of the process, 
                    empowering you to confidently navigate HIPAA security requirements and safeguard patient data effectively. 
                    With our expertise, you can mitigate risks, enhance security posture, and demonstrate a commitment to 
                    protecting sensitive healthcare information.
                    </Typography>
                </Grid>
                <Grid item md={6}>
                  <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
                    <img style={{ width: '600px', height: '500px' }} src={cyber16} alt="alternative_text"></img>
                  </Box>
                  <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <img style={{ width: '350px', height: '290px' }}src={cyber16} alt="alternative_text"></img>
                  </Box>
                </Grid>
                </Grid>
            </Container>
          </Fade>
        </Container>

        <Container maxWidth={false} style={{ height: "auto"}}  sx={{padding: {
          xs: '15px', // Padding for extra small screens
          lg: '70px'},background: theme.palette.primary.gradient}}>
         <Grid  container direction="row" justifyContent="center" alignItems="top" spacing={5}>
          <Grid item md={5} align="center">
            <Typography component={"div"}align="left" color='primary' fontWeight={800} variant="h1">Navigating HIPAA</Typography><br></br><br></br>
            <Box align="left"  sx={{ display: { sm: 'block', xs: 'none' } }}>
              <img style={{ width: '350px', height: '290px' }} src={cyber10} alt="alternative_text"></img>
            </Box>
            <Box align="left"  sx={{ display: { xs: 'block', sm: 'none' } }}>
              <img style={{ width: '350px', height: '290px' }}src={cyber10} alt="alternative_text"></img>
            </Box>
          </Grid>
          <Grid  item md={5}>
            <Accordion defaultExpanded={true} onChange={handleChange1} align='left' sx={{backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
              <AccordionSummary
                sx={{flexDirection: 'row-reverse'}}
                expandIcon={expanded1 ? <RemoveCircleOutlineIcon color="secondary"/> : <AddCircleOutlineIcon  color="secondary"/>}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography component={"div"}color={'primary'} variant="h6" fontWeight={900}  marginLeft={2}>Privacy Rule</Typography> 
              </AccordionSummary>
              <AccordionDetails sx={{color: theme => theme.palette.primary.main}}>
              The Privacy Rule establishes national standards for the protection of individually identifiable health information 
              held or transmitted by covered entities and their business associates. While the Security Rule focuses on electronic 
              protected health information (ePHI), the Privacy Rule applies to all forms of protected health information (PHI).
              </AccordionDetails>
            </Accordion >
            <Accordion expanded={expanded[1]} onChange={handleChange(1)} align='left' sx={{backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
              <AccordionSummary
                sx={{flexDirection: 'row-reverse'}}
                expandIcon={expanded[1] ? <RemoveCircleOutlineIcon color="secondary"/> : <AddCircleOutlineIcon  color="secondary"/>}
                aria-controls="panel1-content"
                id="panel1-header"
              >
               <Typography component={"div"}color={'primary'} variant="h6" fontWeight={900}  marginLeft={2}> Security Rule </Typography> 
              </AccordionSummary>
              <AccordionDetails sx={{color: theme => theme.palette.primary.main}}>
              The HIPAA Security Rule sets national standards for safeguarding electronic protected 
              health information (ePHI). It requires covered entities and their business associates to implement administrative, 
              physical, and technical safeguards to ensure the confidentiality, integrity, and availability of ePHI. This rule focuses 
              on protecting electronic health information from unauthorized access, use, or disclosure.
              </AccordionDetails>
            </Accordion >
            <Accordion expanded={expanded[2]} onChange={handleChange(2)} align='left' sx={{backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
              <AccordionSummary
                sx={{flexDirection: 'row-reverse'}}
                expandIcon={expanded[2] ? <RemoveCircleOutlineIcon color="secondary"/> : <AddCircleOutlineIcon  color="secondary"/>}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography component={"div"}color={'primary'} variant="h6" fontWeight={900} marginLeft={2}>HITECH Act</Typography> 
              </AccordionSummary>
              <AccordionDetails sx={{color: theme => theme.palette.primary.main}}>
              The Health Information Technology for Economic and Clinical Health (HITECH) Act was enacted as part of the American Recovery 
              and Reinvestment Act of 2009. It expanded the scope of HIPAA by introducing new provisions related to electronic health records 
              (EHRs) and breach notification requirements. The HITECH Act also increased penalties for HIPAA violations.
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded[3]} onChange={handleChange(3)} align='left' sx={{backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
              <AccordionSummary
                sx={{flexDirection: 'row-reverse'}}
                expandIcon={expanded[3] ? <RemoveCircleOutlineIcon color="secondary"/> : <AddCircleOutlineIcon  color="secondary"/>}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography component={"div"}color={'primary'} variant="h6" fontWeight={900}  marginLeft={2}> Omnibus Rule</Typography> 
              </AccordionSummary>
              <AccordionDetails sx={{color: theme => theme.palette.primary.main}}>
              This rule, enacted in 2013, modified the HIPAA Privacy, Security, Breach Notification, and Enforcement 
              Rules to implement provisions of the HITECH Act and address other aspects of HIPAA compliance.
              </AccordionDetails>
            </Accordion >
            <Accordion expanded={expanded[4]} onChange={handleChange(4)} align='left' sx={{backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
              <AccordionSummary
                sx={{flexDirection: 'row-reverse'}}
                expandIcon={expanded[4] ? <RemoveCircleOutlineIcon color="secondary"/> : <AddCircleOutlineIcon  color="secondary"/>}
                aria-controls="panel1-content"
                id="panel1-header"
              >
               <Typography component={"div"}color={'primary'} variant="h6" fontWeight={900}  marginLeft={2}> Enforcement Rule</Typography> 
              </AccordionSummary>
              <AccordionDetails sx={{color: theme => theme.palette.primary.main}}>
              This rule outlines the procedures and processes for investigations and penalties related to HIPAA violations. 
              It establishes the authority of the Office for Civil Rights (OCR) to enforce HIPAA regulations and impose civil 
              monetary penalties for non-compliance.
              </AccordionDetails>
            </Accordion >
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth='false'  sx={{padding: {
          xs: '15px', // Padding for extra small screens
          lg: '70px'}}} >
        <Fade unmountOnExit={true} style={{ transformOrigin: '3 3 3', transitionDelay: '0ms' }}
                {...{timeout: {enter:1000, exit:1000} }} in={true}> 
            <Container maxWidth="xl"  style={{ height: "auto"}}  align="center"  >
                <Grid  container direction="row" justifyContent="center" alignItems="center" spacing={10}>
                <Grid item md={5} align="left">
                    <Typography component={"div"}variant="h1" color={'primary'} fontWeight={800}>
                    PCI-DSS 
                    </Typography><br></br>
                    <Typography component={"div"}  color={'primary'}  > 
                     PCI, like HIPAA can be quite challenging, especially for small businesses. This regulation sets forth essential
                     requirements for the protection of cardholder data, imposing strict standards on organizations that handle 
                     payment transactions. Without professional assistance, navigating the detailed demands of PCI-DSS and implementing 
                     the necessary safeguards can be intimitdating. Our expert team specializes in PCI-DSS compliance and provides tailored 
                     services to help your organization meet and maintain these standards. We offer comprehensive risk assessments and 
                     assist with the implementation of effective security measures, guiding you through each phase of the compliance 
                     process. This support empowers you to manage PCI-DSS requirements effectively and secure sensitive payment 
                     information. With our expertise, you can reduce risks, enhance your security posture, and demonstrate your 
                     commitment to protecting cardholder data.
                    </Typography>
                </Grid>
                <Grid item md={6}>
                  <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
                    <img style={{ width: '575px', height: '350px' }} src={cyber22} alt="alternative_text"></img>
                  </Box>
                  <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <img style={{ width: '350px', height: '200px' }}src={cyber22} alt="alternative_text"></img>
                  </Box>
                </Grid>
                </Grid>
            </Container>
          </Fade>
        </Container>

        <Container maxWidth={false} style={{ height: "auto"}}  sx={{padding: {
          xs: '15px', // Padding for extra small screens
          lg: '70px'},background: theme.palette.primary.gradient}}>
         <Grid  container direction="row" justifyContent="center" alignItems="top" spacing={5}>
          <Grid item md={5} align="center">
            <Typography component={"div"}align="left" color='primary' fontWeight={800} variant="h1"> PCI-DSS Requirements</Typography><br></br><br></br>
            <Box align="left"  sx={{ display: { sm: 'block', xs: 'none' } }}>
              <img style={{ width: '350px', height: '290px' }} src={cyber21} alt="alternative_text"></img>
            </Box>
            <Box align="left"  sx={{ display: { xs: 'block', sm: 'none' } }}>
              <img style={{ width: '350px', height: '290px' }}src={cyber21} alt="alternative_text"></img>
            </Box>
          </Grid>
          <Grid  item md={5}>
          <Accordion defaultExpanded={true} onChange={handleChange1} align='left' sx={{backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>  
              <AccordionSummary
                sx={{flexDirection: 'row-reverse'}}
                expandIcon={expanded1 ? <RemoveCircleOutlineIcon color="secondary"/> : <AddCircleOutlineIcon  color="secondary"/>}
                aria-controls="panel1-content"
                id="panel1-header"
              >
               <Typography component={"div"}color={'primary'} variant="h6" fontWeight={900}  marginLeft={2}> Protect Cardholder Data </Typography> 
              </AccordionSummary>
              <AccordionDetails sx={{color: theme => theme.palette.primary.main}}>
              Cardholder data must be encrypted when transmitted over public networks to safeguard it from interception. Additionally, 
              organizations should minimize the storage of sensitive data, retaining only what is necessary for business purposes. If 
              storage is unavoidable, strong encryption and other protective measures should be applied to ensure that data remains secure.
              </AccordionDetails>
            </Accordion >
            <Accordion expanded={expanded[1]} onChange={handleChange(1)} align='left' sx={{backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
              <AccordionSummary
                sx={{flexDirection: 'row-reverse'}}
                expandIcon={expanded[1] ? <RemoveCircleOutlineIcon color="secondary"/> : <AddCircleOutlineIcon  color="secondary"/>}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography component={"div"}color={'primary'} variant="h6" fontWeight={900}  marginLeft={2}>Build and Maintain a Secure Network</Typography> 
              </AccordionSummary>
              <AccordionDetails sx={{color: theme => theme.palette.primary.main}}>
              Establishing a secure network begins with implementing firewalls to shield cardholder data from unauthorized access. 
              Organizations must configure these firewalls and routers to control data traffic and enforce security policies, 
              ensuring that only authorized users can access sensitive data. Regular updates and reviews of these security measures 
              are essential to adapt to evolving threats.
              </AccordionDetails>
            </Accordion >
            
            <Accordion expanded={expanded[2]} onChange={handleChange(2)} align='left' sx={{backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
              <AccordionSummary
                sx={{flexDirection: 'row-reverse'}}
                expandIcon={expanded[2] ? <RemoveCircleOutlineIcon color="secondary"/> : <AddCircleOutlineIcon  color="secondary"/>}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography component={"div"}color={'primary'} variant="h6" fontWeight={900} marginLeft={2}>Maintain a Vulnerability Management Program</Typography> 
              </AccordionSummary>
              <AccordionDetails sx={{color: theme => theme.palette.primary.main}}>
              Organizations must utilize anti-virus software to detect and prevent malware infections on systems that handle cardholder data. 
              Regularly updating this software and applying patches to systems and applications are crucial steps in mitigating vulnerabilities. 
              Establishing secure coding practices during software development also helps prevent security flaws.
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded[3]} onChange={handleChange(3)} align='left' sx={{backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
              <AccordionSummary
                sx={{flexDirection: 'row-reverse'}}
                expandIcon={expanded[3] ? <RemoveCircleOutlineIcon color="secondary"/> : <AddCircleOutlineIcon  color="secondary"/>}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography component={"div"}color={'primary'} variant="h6" fontWeight={900}  marginLeft={2}> Implement Strong Access Control Measures</Typography> 
              </AccordionSummary>
              <AccordionDetails sx={{color: theme => theme.palette.primary.main}}>
              Access to cardholder data should be restricted based on a clear need-to-know basis. This involves assigning unique IDs to individuals who 
              access the data and employing strong authentication methods, such as two-factor authentication, to ensure that only authorized personnel can access 
              sensitive information. Regular reviews of access rights are important to maintain security.
              </AccordionDetails>
            </Accordion >
            <Accordion expanded={expanded[4]} onChange={handleChange(4)} align='left' sx={{backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
              <AccordionSummary
                sx={{flexDirection: 'row-reverse'}}
                expandIcon={expanded[4] ? <RemoveCircleOutlineIcon color="secondary"/> : <AddCircleOutlineIcon  color="secondary"/>}
                aria-controls="panel1-content"
                id="panel1-header"
              >
               <Typography component={"div"}color={'primary'} variant="h6" fontWeight={900}  marginLeft={2}>Regularly Monitor and Test Networks</Typography> 
              </AccordionSummary>
              <AccordionDetails sx={{color: theme => theme.palette.primary.main}}>
              Continuous monitoring of network access helps organizations detect and respond to potential security incidents swiftly. 
              This includes tracking all access to cardholder data and regularly conducting vulnerability scans and penetration testing 
              to identify weaknesses in security controls. Proactive testing ensures that any vulnerabilities are addressed promptly.
              </AccordionDetails>
            </Accordion >
            <Accordion expanded={expanded[5]} onChange={handleChange(4)} align='left' sx={{backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
              <AccordionSummary
                sx={{flexDirection: 'row-reverse'}}
                expandIcon={expanded[5] ? <RemoveCircleOutlineIcon color="secondary"/> : <AddCircleOutlineIcon  color="secondary"/>}
                aria-controls="panel1-content"
                id="panel1-header"
              >
               <Typography component={"div"}color={'primary'} variant="h6" fontWeight={900}  marginLeft={2}>Maintain an Information Security Policy</Typography> 
              </AccordionSummary>
              <AccordionDetails sx={{color: theme => theme.palette.primary.main}}>
              A well-defined information security policy serves as a foundation for protecting cardholder data. This policy 
              should outline security practices, employee responsibilities, and procedures for handling sensitive information. 
              Regular training and awareness programs for employees and contractors are vital to ensure that everyone understands 
              their role in maintaining compliance and safeguarding data.
              </AccordionDetails>
            </Accordion >
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth={false}  sx={{padding: {
          xs: '15px', // Padding for extra small screens
          lg: '70px'}}} >
        <Grid  container direction="row" justifyContent="center" alignItems="center" spacing={5}>
          <Grid item md={10} align="left">
            <Typography component={"div"}variant="h1"   color={'primary'} fontWeight={800}>
              We Can Help
            </Typography><br></br>
            <Typography component={"div"}  sx={{color: theme => theme.palette.primary.main}}  > 
            Navigating the complexities of HIPAA or PCI can be challenging, especially given the intricate web of 
            regulations surrounding them. With our state-of-the-art compliance tools and expertise, we offer 
            tailored solutions to help you ensure compliance with PCI-DSS and the HIPAA Security Rule. From risk assessments to 
            policy development and employee training, we provide comprehensive support to safeguard your 
            organization's sensitive health information and mitigate compliance risks. <br></br><br></br>Let us guide you through 
            the intricacies of regulatory compliance, allowing you to focus on delivering quality service to your customers.
            </Typography>
            <br></br>
            <Link to = "/contact" >
              <Button color="secondary" sx={{width: 180, height: 40, textTransform: 'none', borderRadius: 2 }} variant='contained'>
                <Typography component={"div"} color={theme.palette.primary.lightest} fontWeight={600} > Talk to an expert</Typography>
              </Button>
            </Link>
            <br></br><br></br>
            <br></br>
          </Grid>
        </Grid>
      </Container>
    <Anchor/>
  </div>
    );
  }
  


