import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import InfoIcon from '@mui/icons-material/Info';
import Drawer from '@mui/material/Drawer';

import EmailIcon from '@mui/icons-material/Email';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';
import ListIcon from '@mui/icons-material/List';
import {
Route,
Routes,
Link
} from "react-router-dom";
import { Home } from './Home'
import { Contact } from './Contact'
import { Services } from './Services'
import { WhyAsa } from './WhyASA'
import { Asa } from './Asa'
import { Compliance } from './Compliance'
import  { Training }  from './Training'
import  { Assess }  from './Assess'
import { Continuity } from './Continuity'
import  { Endpoint }  from './Endpoint'
import  { Network }  from './Network'
import { Risk } from './Risk'
import { ReactComponent as MyIcon } from './images/asa.svg';
import { useTheme } from '@mui/material/styles';
import {
  X,
  LinkedIn,
  LocalLibrary,
  Security,
  Assessment,
  CheckCircle,
  Help,
  ManageSearch,
  AssuredWorkload,
  WifiPassword,
  } from '@mui/icons-material';


export function NavBar() {

  const theme = useTheme()

  const [value, setValue] = React.useState('one');
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);

  const toggleDrawer1 = (newOpen1) => () => {
    setOpen1(newOpen1);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };


  const handleChange = (event, newValue) => {
    setValue(newValue);

  }

  const DesktopDrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <List >
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/assessments">
            <ListItemIcon>
              <Assessment color='secondary'/>
            </ListItemIcon>
            <ListItemText ><Typography component={"div"}color={theme.palette.primary.lightest}>Vulnerability Assessments</Typography></ListItemText>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton component={Link} to="/endpoint">
            <ListItemIcon>
              <Security color='secondary'/>
            </ListItemIcon>
            <ListItemText ><Typography component={"div"}color={theme.palette.primary.lightest}>Endpoint Security</Typography></ListItemText>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton component={Link} to="/compliance">
            <ListItemIcon>
              <AssuredWorkload color='secondary'/>
            </ListItemIcon>
            <ListItemText ><Typography component={"div"}color={theme.palette.primary.lightest}>Regulatory Compliance</Typography></ListItemText>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton component={Link} to="/networksecurity">
            <ListItemIcon>
              <WifiPassword color='secondary'/>
            </ListItemIcon>
            <ListItemText ><Typography component={"div"}color={theme.palette.primary.lightest}>Wireless/Network Security</Typography></ListItemText>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton component={Link} to="/training">
            <ListItemIcon>
              <LocalLibrary color='secondary'/>
            </ListItemIcon>
            <ListItemText ><Typography component={"div"}color={theme.palette.primary.lightest}>Training and Awareness</Typography></ListItemText>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton component={Link} to="/continuity">
            <ListItemIcon>
              <CheckCircle color='secondary'/>
            </ListItemIcon>
            <ListItemText ><Typography component={"div"}color={theme.palette.primary.lightest}>Incident Response Planning</Typography></ListItemText>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton component={Link} to="/crq">
            <ListItemIcon>
              <ManageSearch color='secondary'/>
            </ListItemIcon>
            <ListItemText ><Typography component={"div"}color={theme.palette.primary.lightest}>Cyber Risk Quantification</Typography></ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const MobileDrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer1(false)}>
      <List >
      <ListItem disablePadding>
          <ListItemButton component={Link} to="/home">
            <ListItemIcon>
              <HomeIcon color='secondary'/>
            </ListItemIcon>
            <ListItemText ><Typography component={"div"}color={theme.palette.primary.lightest}>Home</Typography></ListItemText>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton component={Link} to="/home">
            <ListItemIcon>
              <HomeIcon color='secondary'/>
            </ListItemIcon>
            <ListItemText ><Typography component={"div"}color={theme.palette.primary.lightest}>Home</Typography></ListItemText>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton component={Link} to="/services">
            <ListItemIcon>
              <MiscellaneousServicesIcon color='secondary'/>
            </ListItemIcon>
            <ListItemText ><Typography component={"div"}color={theme.palette.primary.lightest}>Services</Typography></ListItemText>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton component={Link} to="/asa">
            <ListItemIcon>
              <InfoIcon color='secondary'/>
            </ListItemIcon>
            <ListItemText ><Typography component={"div"}color={theme.palette.primary.lightest}>About Us</Typography></ListItemText>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton component={Link} to="/whyasa">
            <ListItemIcon>
              <Help color='secondary'/>
            </ListItemIcon>
            <ListItemText ><Typography component={"div"}color={theme.palette.primary.lightest}>Why ASA</Typography></ListItemText>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton component={Link} to="/contact">
            <ListItemIcon>
              <EmailIcon color='secondary'/>
            </ListItemIcon>
            <ListItemText ><Typography component={"div"}color={theme.palette.primary.lightest}>Contact</Typography></ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );



  return (
    <div>
      <AppBar position="sticky" >
        <Container maxWidth="lg"> {/* Change maxWidth to lg */}
          <Toolbar disableGutters>
            <Link style={{ textDecoration: 'none' }} to="/home">
              <MyIcon style={{ width: '140px', height: '60px' }} />
              <Box sx={{ display: { md: 'none' } }}/>
            </Link>
            <Box sx={{ flexGrow: 1 }}></Box> 
              <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <Box sx={{ marginRight: '10px' }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor={'inherit'}
                    indicatorColor="secondary"
                  > 
                    <Tab component={Link} to="/home" value="one" label="Home" />                     
                    <Tab component={Link} to="/services" value="two" label="Services" />
                      <IconButton sx={{color: open ? 'white' : 'gray'}} onClick={toggleDrawer(!open)} color="inherit">
                        <ListIcon></ListIcon>
                      </IconButton>
                    <Tab component={Link} to="/asa" value="three" label="  About Us" />
                    <Tab component={Link} to="/whyasa" value="four" label="Why ASA" />
                    <Tab component={Link} to="/contact" value="five" label="Contact Us" />
                    {/* <Tab component={Link} to="/blog" value="five" label="Blog" /> */}
                  </Tabs>
                  <Drawer sx={{width: 250,'& .MuiListItem-root:hover': { backgroundColor: theme.palette.primary.dark},}} 
                  anchor={'right'} open={open} onClose={toggleDrawer(false)}>
                    {DesktopDrawerList}
                  </Drawer>
                </Box>
              </Box>
              <Box sx={{ flexGrow: 1 }}></Box>
            <Box  sx={{ display: { xs: 'none', md: 'block' } }} >
              <IconButton component="a" href="https://www.linkedin.com/company/asa-cybersecurity"  target="_blank" rel="noopener noreferrer">
                <LinkedIn target="_blank" style={{color: theme.palette.primary.lightest, height: 30, width: 30, marginRight: '10px'}}/>
              </IconButton>
              <IconButton component="a" href="https://x.com/ASACyberOmaha" target="_blank" rel="noopener noreferrer">
                <X style={{ color: theme.palette.primary.lightest, height: 30, width: 30 }} />
              </IconButton>
            </Box>
            
            
           
            <Box sx={{ display: { xs: 'block', md: 'none' } }} >
              <IconButton  onClick={toggleDrawer1(!open1)} edge="start" color="inherit">
                <MenuIcon aria-controls="simple-menu" aria-haspopup="true"  />
              </IconButton>
              <Drawer  sx={{zIndex: theme.zIndex.appBar - 1, width: 250,'& .MuiListItem-root:hover': { backgroundColor: '#2F1A54'},}} 
                  anchor={'right'} open={open1} onClose={toggleDrawer1(false)}>
                {MobileDrawerList}
              </Drawer>
            </Box>
           
          </Toolbar>
        </Container>
      </AppBar>


      <Routes>
       <Route path="/home" element={<Home/>}/>
       <Route exact path="/" element={<Home/>}/>
       <Route path="/training" element={<Training/>}/>
       <Route path="/contact" element={<Contact/>}/>
       <Route path="/assessments" element={<Assess/>}/>
       <Route path="/whyasa" element={<WhyAsa/>}/>
       <Route path="/asa" element={<Asa/>}/>
       <Route path="/compliance" element={<Compliance/>}/>
       <Route path="/services" element={<Services/>}/>
       <Route path="/continuity" element={<Continuity/>}/>
       <Route path="/endpoint" element={<Endpoint/>}/>
       <Route path="/networksecurity" element={<Network/>}/>
       <Route path="/crq" element={<Risk/>}/>
     </Routes>


    </div>
  );
}
