import './slick-overrides.css';
import { NavBar } from "./NavBar"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router,  } from "react-router-dom";
import { CssBaseline } from '@mui/material';
import { ErrorBoundary } from './ErrorBoundary';

function App() {
  const theme = createTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            color: '#000000',//idk wtf this is
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          list: {
            '&[role="menu"]': {
            backgroundColor: '#000000'
            },
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: '#000000',//idk wtf this is
          },
        },
      },
    },
    
    palette: {
      primary: {
        main: '#000000',
        gradient: 'linear-gradient(90deg, rgba(112,66,252,.64), RGB(255, 255, 255,0) 130%)',
        dark: '#2F1A54',
        white: '#FFFFFF',
        lighter: '#C3C3C3',
        lightest:'#F4F4F4', 
        trans: "#000000B3" //rgba(0, 0, 0, 0.7)
      },
      secondary: {
        main: '#7042FC',
        light: '#DDDDDD',
        dark: '#24155F',
        bg: '#FCFCFC'
        
      },
    },
    typography: {
      fontFamily: '"Open Sans", sans-serif',
      fontSize: 14,
      title: {
        fontSize: 48, 
      },
      h1: {
        fontSize: 40, 
      },
      h2: {
        fontSize: 30, 
      },
      h3: {
        fontSize: 22, 
      },
    },
  });
  
  
  return (
    <div style={{backgroundColor: theme.palette.secondary.bg}} className="flex-container">
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <CssBaseline>
            <Router>
                <NavBar/> 
            </Router>
          </CssBaseline>
        </ThemeProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
