import React, { useState, useEffect } from 'react';
import Divider from '@mui/material/Divider';
import ScrollAnimation from 'react-animate-on-scroll';
import {Link} from "react-router-dom";
import { Anchor } from "./Anchor";
import { 
  Typography, 
  Container, 
  Button,
  Box,
  Fade,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Grid
 } from '@mui/material';
 import { 
   ExpandMore,
   East
 } from '@mui/icons-material';
import { FcOk } from "react-icons/fc";
import { FcDiploma1 } from "react-icons/fc";
import { FcDoughnutChart } from "react-icons/fc";
import { FcMultipleDevices } from "react-icons/fc";
import { FcMindMap } from "react-icons/fc";
import { FcLibrary } from "react-icons/fc";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './slick-overrides.css';
import "animate.css/animate.compat.css"
import malware from './images/malware.jpg'
import socialengineering from './images/socialengineering.jpg'
import ransomware from './images/ransomware.jpg'
import ransomware2 from './images/ransomware2.jpg'
import { useTheme } from '@mui/material/styles';
import CountUp from 'react-countup';
import consulting from './images/skyline.jpg'
import mindset from './images/mindset.svg'
import asa from './images/asa.png'
import './App.css';
import { TypeAnimation } from 'react-type-animation';
import wallpaper from './images/asabg.png'

export function Home() {

  const theme = useTheme()

  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    speed: 1100,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  const [isExpanded, setIsExpanded] = useState(true);
  const [fadeDelay, setFadeDelay] = useState(false);

  useEffect(()=>{
    // window.scrollTo(0, 0);

    setTimeout(function() {
      setFadeDelay(true)
  }, 400); // Executes the function after 3000 milliseconds (3 seconds)
  
  },[])


  
  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  }

  return (
    <div >
        <Container  maxWidth={false} style={{backgroundPosition: 'center', backgroundImage: `url(${consulting})`,backgroundSize: "cover", 
        alignItems: "center", minHeight: "500px", display: 'flex'}} sx={{p: 5}} align="center"  >
          {fadeDelay? 
          <Fade mountOnEnter unmountOnExit 
              {...{timeout: {enter:2500, exit:1000} }}  in={true}> 
              <Container align="left" maxWidth='md' sx={{borderRadius: 2, p: 3}} style={{backgroundColor: theme.palette.primary.trans}} >
              <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
              <Typography component={"div"} variant='title' color={theme.palette.primary.lightest}  fontWeight='900' > 
                Omaha's Trusted IT Security Partner 
                </Typography>               
                </Box>
              <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
              <Typography component={"div"} variant='h1' color={theme.palette.primary.lightest}  fontWeight='900' > 
                Omaha's Trusted Security Partner  
                </Typography>                
                </Box>   
                <Divider style={{backgroundColor:theme.palette.secondary.main, width: '125px', height: '3px' }} /> 
                <br></br>
                <Typography component={"div"} variant='h3' style={{display: 'inline'}} color={theme.palette.primary.lightest} fontWeight='500' > 
                Shield your business with comprehensive protection against today's advanced cyber threats.
                </Typography>
                <br></br><br></br>
                <Link to = "/contact" >
                  <Button color="secondary" sx={{width: 255, height: 45, textTransform: 'none', borderRadius: 2 }} variant='contained'>
                    <Typography style={{ marginRight: '10px'}} component={"div"}  color={theme.palette.primary.lightest} fontWeight={600} > Schedule a Consultation</Typography>
                    <East  sx={{color: theme => theme.palette.primary.lightest}} fontSize='medium'  />
                  </Button>
                </Link>
              </Container>
          </Fade>
          :""} 
        </Container>

        <Container maxWidth={'false'} style={{height: "auto"}}  sx={{p:5}} align="center"  >
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={10}>
            <Grid item md={5} align="left">  
            <Typography component={"div"}variant="h1"  color={'primary'} fontWeight={600}>
              <TypeAnimation
                sequence={[
                  0,
                  'Cybersecurity With ASA'
                ]}
                wrapper="div"
                cursor={false}
                speed={60}
                // style={{ fontSize: theme.typography.title.fontSize, color: theme.palette.primary.lightest }}
              >
              </TypeAnimation>
            </Typography>
            <Typography component={"div"}variant="h3" color={'secondary'} fontWeight={600}>
            <TypeAnimation
                sequence={[
                  1400,
                  'Securing Your Future With Trust and Confidence'
                ]}
                wrapper="div"
                cursor={false}
                speed={60}
                // style={{ fontSize: theme.typography.title.fontSize, color: theme.palette.primary.lightest }}
              >
              </TypeAnimation>
            </Typography><br></br>
            <Typography component={"div"}color={'primary'}  style={{ display: 'inline' }}  > From the speed of innovation, digital transformation, 
              and a dynamic regulatory landscape, technology leaders are expected to effectively respond to and manage these competing priorities. To grow 
              securely while reducing risk, your cybersecurity posture needs to adapt and respond to your business changing. ASA Can help. 
            </Typography>
              {isExpanded ? 
            <Button variant='text' style={{ display: 'inline' }} onClick={toggleExpanded} color="secondary">
                  Read More
            </Button>
              :""}
                  
              {!isExpanded? 
              <Typography component={"div"} style={{ display: 'inline' }}  color={'primary'}>
              {" "}As technology rapidly evolves and digital adoption 
              accelerates, ASA's cybersecurity and privacy team turns risk into an advantage – protecting every layer of an organization to unlock new opportunities, securely.
              Our strategic and technical subject matter experts fully understand your cybersecurity needs. Utilizing industy best practices and frameworks,  
              we set out to help your business assess, develop, implement, and manage end-to-end next-generation 
              solutions tailored to your specific needs. We share your commitment to protecting your data and optimizing your business and cyber resiliency.
              </Typography >
              : ""}
              {!isExpanded ? 
              <Button variant='text' style={{ display: 'inline' }} onClick={toggleExpanded} color="secondary">
                  Read Less
              </Button>
              :""}
              <br></br>
            </Grid>
            <Grid item md={5}>
              <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
                <img style={{ width: '300px', height: '300px' }} src={asa} alt="alternative_text"></img>
              </Box>
              <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <img style={{ width: '300px', height: '300px' }} src={asa} alt="alternative_text"></img>
              </Box>
            </Grid>
          </Grid>
        </Container>

        
        <Container maxWidth={'false'} style={{backgroundColor: theme.palette.primary.main, height: "auto"}}  align="center"  >
          <Container sx={{padding: {
          xs: '0px', // Padding for extra small screens
          lg: '50px'} }} maxWidth={'lg'} >
          <ScrollAnimation animateOnce animateIn="fadeIn">
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
              <Typography component={"div"}variant="h2" sx={{p:3}} color={theme.palette.primary.lightest} fontWeight={600}>
              Work with a Cybersecurity Consulting Firm in Omaha
              that Knows Your Industry.
              </Typography> <Divider style={{backgroundColor:theme.palette.secondary.main, width: '75px', height: '2px' }} /><br></br>
            </Box>
            <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
              <Typography component={"div"}variant="h1"  sx={{p:2}} color={theme.palette.primary.lightest} fontWeight={600}>
              Work with a Cybersecurity Consulting Firm in Omaha
              that Knows Your Industry.
              </Typography> <Divider style={{backgroundColor:theme.palette.secondary.main, width: '75px', height: '2px' }} /><br></br>
            </Box>
              <Typography component={"div"}textAlign={'left'} variant="h3"  color={theme.palette.primary.lightest}  > 
              The cybersecurity consulting industry is full of general technology solutions aimed across any sized business. 
              To get the best outcomes, you need to work with a cybersecurity consulting firm in Omaha that knows your space. We’ll deploy best practices, technology, and processes 
              that align to the unique challenges of your industry – keeping your data secure, your network running, and budget in check.
              </Typography>
              <br></br>
            </ScrollAnimation>
            </Container>
        </Container>

        <Container maxWidth="false" sx={{background: theme.palette.primary.lightest, padding: {
          xs: '25px', // Padding for extra small screens
          sm: '15px', // Padding for small screens
          md: '20px', // Padding for medium screens
          lg: '80px'}}}  align="center"  >
          {/* <ScrollAnimation animateOnce animateIn="slideInRight"> */}
          <Container  maxWidth="xl" style={{height: "auto"}}  align="center"  >
            <Grid  container direction="row" justifyContent="center" alignItems="center" spacing={10}>
              <Grid  item lg={3} align="center">
                <CountUp style={{ fontSize: '58px', fontWeight: 'bold', color: theme.palette.secondary.main }} enableScrollSpy={true} duration={3} end={47} />
                <Typography variant='h3'>
                  % of small businesses have experienced at least one cyber attack
                </Typography>
              </Grid>
              <Grid item lg={3}  align="center">
              <CountUp style={{ fontSize: '58px', fontWeight: 'bold', color: theme.palette.secondary.main }} enableScrollSpy={true} duration={3} end={43} />
                <Typography variant='h3'>
                  % of Breaches from Insider Threats
                </Typography>
              </Grid>
              <Grid  item lg={3} align="center">
              <CountUp style={{ fontSize: '58px', fontWeight: 'bold', color: theme.palette.secondary.main }} enableScrollSpy={true} duration={3} end={95} />
               <Typography variant='h3'>
                  % of Breaches Caused by Human Error
                </Typography>
              </Grid>
              <Grid item lg={3}  align="center">
              <CountUp style={{ fontSize: '58px', fontWeight: 'bold', color: theme.palette.secondary.main }} enableScrollSpy={true} duration={3} end={39} />
                <Typography variant='h3'>
                  Seconds Between Each Cyberattack
                </Typography>
              </Grid>
            </Grid>
          </Container>
          {/* </ScrollAnimation> */}
        </Container>

        <Container maxWidth="false" align="center" sx={{background: '#000000'}} > 
          <Container maxWidth={'xl'} sx={{color: theme.palette.primary.lightest, width: {xl: '80%' }, p: 5 }}> <Typography component={"div"}sx={{p:2}} fontWeight={600} variant='h1'>We Help With</Typography>
          <Divider  style={{backgroundColor:theme.palette.secondary.main, width: '75px', height: '2px' }} />
            <Grid sx={{p:3}} container alignItems="stretch" direction="row" justifyContent="center"  spacing={6}>
            
              <Grid item xl={4}>
                <Link  style={{ textDecoration: 'none'}} to="/networksecurity">
                  <div >
                  <ScrollAnimation animateOnce animateIn="slideInLeft">
                    <Paper elevation={15} sx={{background:`${theme.palette.primary.light}`, backgroundSize: 'cover', width: {xs: '350px'}, borderRadius: 7, transition: 'background 0.5s', '&:hover': {background: `url(${wallpaper})`, backgroundPosition: 'center', backgroundSize: 'cover'},  p: 5//, minHeight: '600px', minWidth: '350px'
                        }}  style={{ overflow: 'auto', wordWrap: 'break-word' }}>
                          <FcMindMap size={'3em'}/> 
                        <Typography component={"div"}color={theme.palette.primary.white} style={{ textDecoration: 'none'}} textAlign="center" flexWrap="wrap" fontSize={'2rem'} fontWeight={650}>Wireless and Network Security</Typography>
                        <Divider style={{backgroundColor:theme.palette.secondary.main, width: '105px', height: '2px' }} />
                        <br></br>
                        <Typography component={"div"} color={theme.palette.primary.lighter} textAlign="left" fontSize={'1.rem'}>We offer tailored solutions to protect your digital infrastructure. Through thorough assessments and implementation of robust 
                        security measures, we ensure your network stays resilient against cyber threats, enabling your business to operate securely in today's digital world.</Typography>
                    </Paper>
                    </ScrollAnimation>
                  </div>
                </Link>
              </Grid>
              

              
              <Grid item xl={4}>
                <Link  style={{ textDecoration: 'none'}} to="/endpoint">
                  <div >
                  <ScrollAnimation animateOnce animateIn="slideInLeft">
                    <Paper elevation={15} sx={{background:`${theme.palette.primary.light}`, backgroundSize: 'cover', width: {xs: '350px'}, borderRadius: 7, transition: 'background 0.5s', '&:hover': {background: `url(${wallpaper})`, backgroundPosition: 'center', backgroundSize: 'cover'},  p: 5//, minHeight: '600px', minWidth: '350px'
                        }}  style={{ overflow: 'auto', wordWrap: 'break-word' }}>
                          <FcMultipleDevices size={'3em'} />
                        <Typography component={"div"}color={theme.palette.primary.white} style={{ textDecoration: 'none'}} textAlign="center" flexWrap="wrap" fontSize={'2rem'} fontWeight={650}>
                          Endpoint Security
                          </Typography>
                        <Divider style={{backgroundColor:theme.palette.secondary.main, width: '105px', height: '2px' }} />
                        <br></br>
                        <Typography component={"div"} color={theme.palette.primary.lighter} textAlign="left" fontSize={'1.rem'}> Through advanced technologies and best practices, we offer tailored protection for your devices. 
                        Our solutions encompass robust defenses against malware, unauthorized access, and breaches, maintaining network security and data integrity.</Typography>
                    </Paper>
                  </ScrollAnimation>
                  </div>
                </Link>
              </Grid>

              <Grid  item xl={4}>
                <Link  style={{ textDecoration: 'none'}} to="/assessments">
                  <div >
                  <ScrollAnimation animateOnce animateIn="slideInLeft">
                    <Paper elevation={15} sx={{background:`${theme.palette.primary.light}`, backgroundSize: 'cover', width: {xs: '350px'}, borderRadius: 7, transition: 'background 0.5s', '&:hover': {background: `url(${wallpaper})`, backgroundPosition: 'center', backgroundSize: 'cover'},  p: 5//, minHeight: '600px', minWidth: '350px'
                        }}  style={{ overflow: 'auto', wordWrap: 'break-word' }}>
                          <FcDoughnutChart size={'3em'} />
                        <Typography component={"div"}color={theme.palette.primary.white} style={{ textDecoration: 'none'}} textAlign="center" flexWrap="wrap" fontSize={'2rem'} fontWeight={650}> Vulnerability Assessments</Typography>
                        <Divider style={{backgroundColor:theme.palette.secondary.main, width: '105px', height: '2px' }} />
                        <br></br>
                        <Typography component={"div"} color={theme.palette.primary.lighter} textAlign="left" fontSize={'1.rem'}>Harnessing advanced technologies and best practices, our vulnerability assessment service 
                          offers tailored protection for your digital assets. We defend against malware, unauthorized access and breaches, ensuring robust network security; safeguarding data confidentiality, integrity, and availability.
                        </Typography>
                    </Paper>
                  </ScrollAnimation>
                  </div>
                </Link>
              </Grid>
              
              <Grid item xl={4}>
                <Link  style={{ textDecoration: 'none'}} to="/compliance">
                  <div >
                  <ScrollAnimation animateOnce animateIn="slideInRight">
                    <Paper elevation={15} sx={{background:`${theme.palette.primary.light}`, backgroundSize: 'cover', width: {xs: '350px'}, borderRadius: 7, transition: 'background 0.5s', '&:hover': {background: `url(${wallpaper})`, backgroundPosition: 'center', backgroundSize: 'cover'},  p: 5//, minHeight: '600px', minWidth: '350px'
                        }}  style={{ overflow: 'auto', wordWrap: 'break-word' }}> 
                          <FcLibrary size={'3em'}/>
                        <Typography component={"div"}color={theme.palette.primary.white} style={{ textDecoration: 'none'}} textAlign="center" flexWrap="wrap" fontSize={'2rem'} fontWeight={650}>Regulatory Compliance</Typography>
                        <Divider style={{backgroundColor:theme.palette.secondary.main, width: '105px', height: '2px' }} />
                        <br></br>
                        <Typography component={"div"} color={theme.palette.primary.lighter} textAlign="left" fontSize={'1.rem'}>As compliance consultants, we specialize in HIPAA, PCI-DSS, CIS, and other regulations. Our tailored solutions ensure your business meets 
                        regulatory requirements, addressing compliance gaps and safeguarding operations for seamless, legally compliant processes.</Typography>
                    </Paper>
                    </ScrollAnimation>
                  </div>
                </Link>
              </Grid>

              <Grid item xl={4}>
                <Link  style={{ textDecoration: 'none'}} to="/training">
                  <div >
                  <ScrollAnimation animateOnce  animateIn="slideInRight">
                    <Paper elevation={15} sx={{background:`${theme.palette.primary.light}`, backgroundSize: 'cover', width: {xs: '350px'}, borderRadius: 7, transition: 'background 0.5s', '&:hover': {background: `url(${wallpaper})`, backgroundPosition: 'center', backgroundSize: 'cover'},  p: 5//, minHeight: '600px', minWidth: '350px'
                        }}  style={{ overflow: 'auto', wordWrap: 'break-word' }}>
                          <FcDiploma1  size={'3em'} />
                        <Typography component={"div"}color={theme.palette.primary.white} style={{ textDecoration: 'none'}} textAlign="center" flexWrap="wrap" fontSize={'2rem'} fontWeight={650} >Training and Awareness</Typography>
                        <Divider style={{backgroundColor:theme.palette.secondary.main, width: '105px', height: '2px' }} />
                        <br></br>
                        <Typography component={"div"} color={theme.palette.primary.lighter} textAlign="left" fontSize={'1.rem'}>From seminars to phishing simulations, our training and awarness solutions ensure employees are equipped to recognize and respond 
                        to threats. Through engaging programs, we empower your team, fortifying your defenses against cybercriminals.</Typography>
                    </Paper>
                    </ScrollAnimation>
                  </div>
                </Link>
              </Grid>

              <Grid item xl={4}>
                <Link  style={{ textDecoration: 'none'}} to="/services">
                  <div >
                  <ScrollAnimation animateOnce  animateIn="slideInRight">
                    <Paper elevation={15} sx={{background:`${theme.palette.primary.light}`, backgroundSize: 'cover', width: {xs: '350px'}, borderRadius: 7, transition: 'background 0.5s', '&:hover': {background: `url(${wallpaper})`, backgroundPosition: 'center', backgroundSize: 'cover'},  p: 5//, minHeight: '600px', minWidth: '350px'
                        }}  style={{ overflow: 'auto', wordWrap: 'break-word' }}>
                          <FcOk size={'3em'}/>
                        <Typography component={"div"} color={theme.palette.primary.white} style={{ textDecoration: 'none'}} textAlign="center" flexWrap="wrap" fontSize={'2rem'} fontWeight={650}>And Much More!</Typography>
                        <Divider style={{backgroundColor:theme.palette.secondary.main, width: '105px', height: '2px' }} />
                        <br></br>
                        <Typography component={"div"} color={theme.palette.primary.lighter} textAlign="left" fontSize={'1.rem'}>
                          ASA offers a host of other services to help your business stay secure. From Risk Management development and implementation to general IT services, ASA can help your
                          business succeed in this digitial world.
                        </Typography><br></br>
                        <East sx={{color: theme => theme.palette.primary.lightest}} style={{ fontSize: 60 }}   />
                    </Paper>
                    </ScrollAnimation>
                  </div>
                </Link>
              </Grid>
            </Grid>
          </Container>
        </Container>  

        <div >
        <Container maxWidth='false' align="center"  justifyContent="center" sx={{display: { xs: 'none', sm: 'block' }, p:8, background: theme.palette.primary.gradient}}>
            <Slider {...settings}>     
              <div >
                <Container maxWidth='false' style={{p : 5}}  align="center"  >
                  <Grid  container direction="row" justifyContent="center" alignItems="center" spacing={10}>
                    <Grid item lg={6}>
                      <Box sx={{ display: { xl: 'block', lg: 'none', md: 'none', sm: 'none' } }}>
                        <img src={ransomware2} alt="alternative_text"></img>
                      </Box>
                      <Box sx={{ display: {xl: 'none', lg: 'block', md: 'none', sm: 'none' } }}>
                        <img style={{ width: '600px', height: '450px' }} src={ransomware2} alt="alternative_text"></img>
                      </Box>
                      <Box sx={{ display: {xl: 'none', md: 'block', sm: 'block', lg: 'none'} }}>
                        <img style={{ width: '700px', height: '500px' }} src={ransomware2} alt="alternative_text"></img>
                      </Box>
                    </Grid>
                    <Grid item lg={6} align="left">
                      <Typography component={"div"}variant="h1" fontWeight={600} color={theme.palette.primary.main} >
                      91% of Cyber Attacks Begin With Phishing<Typography component={"div"}style={{ display: 'inline' }} color={'gray'}  >
                      <a style={{ textDecoration: 'none', color: 'inherit' }} href="https://blog.cloudflare.com/2023-phishing-report">{" "}Cloudflare, 2024</a></Typography> 
                      </Typography><br></br>
                      <Typography component={"div"}  color={theme.palette.primary.main}  > 
                      In the cybersecurity landscape of 2023, a staggering revelation emerged: 91% of cyber intrusions initiated with 
                      cunningly crafted phishing attacks. Exploiting the inherent vulnerability of human nature, cybercriminals employed 
                      sophisticated tactics via email to ensnare unsuspecting users, breaching corporate defenses and compromising sensitive 
                      data. This posed a significant risk to businesses across industries, heightening the urgency for 
                      robust cybersecurity measures. By prioritizing proactive defense strategies and empowering organizations with tailored solutions, 
                      ASA Cybersecurity ensures not only the protection of valuable assets but also the preservation of trust and 
                      reputation in an increasingly perilous digital landscape
                      </Typography>
                      <br></br>
                      <Link to = "/services" >
                      <Button color="secondary" sx={{width: 210, height: 40, textTransform: 'none', borderRadius: 2 }} variant='contained'>
                          <Typography component={"div"} color={theme.palette.primary.lightest} fontWeight={600} > See How We Can Help</Typography>
                      </Button>
                      </Link>
                    </Grid>
                  </Grid>
                </Container>
              </div>
              <div>
                <Container maxWidth='false' style={{  p:5}}  align="center"  >
                  <Grid  container direction="row" justifyContent="center" alignItems="center" spacing={10}>
                    <Grid item lg={6}>
                      <Box sx={{ display: { xl: 'block', lg: 'none', md: 'none', sm: 'none' } }}>
                        <img style={{ width: '700px', height: '500px' }}  src={ransomware} alt="alternative_text"></img>
                      </Box>
                      <Box sx={{ display: {xl: 'none', lg: 'block', md: 'none', sm: 'none' } }}>
                        <img style={{ width: '600px', height: '450px' }} src={ransomware} alt="alternative_text"></img>
                      </Box>
                      <Box sx={{ display: {xl: 'none', md: 'block', sm: 'block', lg: 'none'} }}>
                        <img style={{ width: '700px', height: '500px' }} src={ransomware} alt="alternative_text"></img>
                      </Box>
                    </Grid>
                    <Grid item lg={6} align="left">
                      <Typography component={"div"}variant="h1"  color={theme.palette.primary.main} fontWeight={600}>
                        Ransomware Increased 68% in 2023 <Typography component={"div"}style={{ display: 'inline' }} color={'gray'}  ><a style={{ textDecoration: 'none', color: 'inherit' }} href="https://try.threatdown.com/2024-state-of-malware/?ref=pressrelease">Threatdown, 2024</a></Typography>
                      </Typography><br></br>
                      <Typography component={"div"}color={theme.palette.primary.main}  > 
                      In 2023 ransomware attacks surged to unprecedented levels. This was in large part because of a U.S. DOJ-led takedown of QakBot, 
                      a banking trojan that has been active for over a decade. To fill the void of lost income streams, cybercriminals redirected their efforts 
                      on ransomware attacks, particularly on smaller, less protected Healthcare companies. The use of ransomware tools like Darknet 
                      rose 880% over the previous year. With this unprecedented explosion of ransomware attacks, you can't be too careful when it comes to your 
                      customers and your IT systems. ASA Cybersecurity can make sure your business is protected. Using our state-of-the-art tools
                      and technologies, we can help ensure the confidentiality, integrity, and availability of  
                      your IT systems, providing you peace of mind, and continued years of success for your business.
                      </Typography>
                      <br></br>
                      <Link to = "/assessments" >
                        <Button color="secondary" sx={{width: 210, height: 40, textTransform: 'none', borderRadius: 2 }} variant='contained'>
                          <Typography component={"div"}  color={theme.palette.primary.lightest} fontWeight={600} > See How We Can Help</Typography>
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                </Container>
              </div>
              <div>
                <Container maxWidth='false' style={{p:5}}  align="center"  >
                  <Grid  container direction="row" justifyContent="center" alignItems="center" spacing={10}>
                    <Grid item lg={6}>
                      <Box sx={{ display: { xl: 'block', lg: 'none', md: 'none', sm: 'none' } }}>
                        <img src={socialengineering} alt="alternative_text"></img>
                      </Box>
                      <Box sx={{ display: {xl: 'none', lg: 'block', md: 'none', sm: 'none' } }}>
                        <img style={{ width: '600px', height: '400px' }} src={socialengineering} alt="alternative_text"></img>
                      </Box>
                      <Box sx={{ display: {xl: 'none', md: 'block', sm: 'block', lg: 'none'} }}>
                        <img style={{ width: '700px', height: '500px' }} src={socialengineering} alt="alternative_text"></img>
                      </Box>
                    </Grid>
                    <Grid item lg={6} align="left">
                      <div>
                      <Typography component={"div"}variant="h1"   color={theme.palette.primary.main} fontWeight={600}>
                        Social Engineering Accounts for 98% of ALL Cyber Attacks <Typography component={"div"}style={{ display: 'inline' }} color={'gray'}  ><a style={{ textDecoration: 'none', color: 'inherit' }} href="https://www.statista.com/topics/8385/phishing/#topicOverview">Statista, 2024</a></Typography>
                      </Typography>
                      </div><br></br>
                      <Typography component={"div"}color={theme.palette.primary.main}  > 
                      In today's cyber landscape, social engineering reigns supreme, constituting a staggering 98% of all attacks. Exploiting human psychology rather than technical flaws, 
                      it poses a formidable challenge to cybersecurity. Businesses face a myriad of deceptive tactics, from phishing emails to pretexting calls, amidst the surge in remote 
                      work and digital reliance. At ASA Cybersecurity, we recognize the urgency of combatting these threats. Our holistic approach blends cutting-edge technology with 
                      employee training to shield your organization from social engineering attacks. Partner with us to safeguard your sensitive data and uphold trust in an interconnected world.
                      </Typography>
                      <br></br>
                      <Link to = "/services" >
                        <Button color="secondary" sx={{width: 210, height: 40, textTransform: 'none', borderRadius: 2 }} variant='contained'>
                          <Typography component={"div"}  color={theme.palette.primary.lightest} fontWeight={600} > See How We Can Help</Typography>
                        </Button>
                      </Link>
                      <br></br><br></br>
                      
                    </Grid>
                  </Grid>
                </Container>
              </div>
              <div>
                <Container maxWidth='false' style={{p:5}}  align="center"  >
                  <Grid  container direction="row" justifyContent="center" alignItems="center" spacing={10}>
                    <Grid item lg={6}>
                      <Box sx={{ display: { xl: 'block', lg: 'none', md: 'none', sm: 'none' } }}>
                        <img src={malware} alt="alternative_text"></img>
                      </Box>
                      <Box sx={{ display: {xl: 'none', lg: 'block', md: 'none', sm: 'none' } }}>
                        <img style={{ width: '600px', height: '450px' }} src={malware} alt="alternative_text"></img>
                      </Box>
                      <Box sx={{ display: {xl: 'none', md: 'block', sm: 'block', lg: 'none'} }}>
                        <img style={{ width: '700px', height: '500px' }} src={malware} alt="alternative_text"></img>
                      </Box>
                    </Grid>
                    <Grid item lg={6} align="left">
                      <Typography component={"div"}variant="h1"   color={theme.palette.primary.main} fontWeight={600}>
                      61% of SMBs Were Hit by a Successful Cyberattack in the Past Year <Typography component={"div"}style={{ display: 'inline' }} color={'gray'}  ><a style={{ textDecoration: 'none', color: 'inherit' }} href="https://www.blackfog.com/smbs-were-victims-cyberattack/">Blackfog, 2024</a></Typography> 
                      </Typography><br></br>
                      <Typography component={"div"}   color={theme.palette.primary.main}  > 
                       Last year 61% of small and medium-sized businesses (SMBs) fell victim to successful cyberattacks. This alarming trend 
                       underscored the heightened vulnerability of SMBs to digital threats, highlighting the urgent need 
                       for fortified defenses. Cybercriminals seized upon this vulnerability, exploiting gaps in security 
                       measures to breach SMB networks and compromise critical data. The ramifications of these attacks 
                       were profound, ranging from financial losses to reputational damage. ASA Cybersecurity is a stalwart ally 
                       for SMBs, offering tailored solutions designed to bolster their defenses against cyber threats. Through a 
                       combination of advanced threat intelligence and proactive security measures, ASA Cybersecurity empowers SMBs to 
                       navigate the digital landscape with confidence, safeguarding their assets and preserving their viability 
                       in an ever-evolving threat environment.
                      </Typography>
                      <br></br>
                      <Link to = "/services" >
                        <Button color="secondary" sx={{width: 210, height: 40, textTransform: 'none', borderRadius: 2 }} variant='contained'>
                          <Typography component={"div"}  color={theme.palette.primary.lightest} fontWeight={600} > See How We Can Help</Typography>
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                </Container>
              </div>
            </Slider>
          </Container>
        </div>

        <Container sx={{padding: {
          xs: '0px', // Padding for extra small screens
          sm: '15px', // Padding for small screens
          md: '20px', // Padding for medium screens
          lg: '50px', // Padding for large screens and above
        },
      }} maxWidth="false" style={{backgroundColor: theme.palette.primary.main, height: "auto"}} align="center"  >
        <ScrollAnimation animateOnce animateIn="fadeIn">
          <Typography component={"div"}sx={{p:2}} variant='h4' color={theme.palette.primary.lightest}>ASA Cybersecurity FAQs</Typography>
          <Divider style={{backgroundColor:theme.palette.secondary.main, width: '105px', height: '2px' }} />
          {/* <Container maxWidth="xl" style={{ height: "auto"}} sx={{ p: 5}} align="center"  > */}<br></br>
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={0}>
            <Grid item md={10} >
            <Accordion defaultExpanded={true} align='left' variant='outlined' sx={{backgroundColor: theme.palette.primary.main, outline: 
              `1px solid ${theme.palette.primary.lightest}`, borderBottom: `1px solid ${theme.palette.primary.lightest}`}}>
              <AccordionSummary
                sx={{flexDirection: 'row-reverse'}}
                expandIcon={<ExpandMore  color="secondary"/>}
                aria-controls="panel25-content"
                id="panel25-header"
              >
               <Typography component={"div"}color={theme => theme.palette.primary.lightest} variant="h6" fontWeight={700}  marginLeft={2}>Why do I need a cybersecurity consultant? </Typography> 
              </AccordionSummary>
              <AccordionDetails sx={{color: theme => theme.palette.primary.lightest}}>
              Cyber threats are multiplying at an alarming rate. According to Watchguard, there was a cyber attack every 39 seconds in 2023--nearly 800,000. 
              As a small business owner, you're already juggling multiple tasks, striving to grow your business while safeguarding your valuable data from these relentless threats. 
              That's where a cybersecurity consultant becomes your indispensable ally. They help you navigate this ever-changing maze of risks, ensuring your data stays safe and
              your business stays compliant with regulations.              
              </AccordionDetails>
            </Accordion >
        
            <Accordion  align='left' variant='standard' sx={{backgroundColor: theme.palette.primary.main,outline: `1px solid ${theme.palette.primary.lightest}`, borderBottom: `1px solid ${theme.palette.primary.lightest}`}}>
              <AccordionSummary
                sx={{flexDirection: 'row-reverse'}}
                expandIcon={<ExpandMore color="secondary" />}
                aria-controls="panel13-content"
                id="panel13-header"
              >
                <Typography component={"div"}color={theme => theme.palette.primary.lightest} variant="h6" fontWeight={700}  marginLeft={2}>Do I need a consultant if my IT infrastructure is cloud-based?</Typography> 
              </AccordionSummary>
              <AccordionDetails sx={{color: theme => theme.palette.primary.lightest}}>
              Absolutely, having a cloud-based IT infrastructure doesn't eliminate the need for a cybersecurity consultant; in fact, it may even heighten the importance of having one. Data 
              breaches increased 20% from 2022 to 2023, and according to Harvard Business Review, the #1 cause was Cloud misconfiguration. A cybersecurity consultant can assess your cloud environment, identify potential vulnerabilities, 
              and implement robust security measures to protect your data and assets. They provide expert guidance on configuring and managing cloud security controls, ensuring compliance with 
              industry regulations, and proactively monitoring for threats. They help safeguard your business from cyber threats and provide peace of mind, allowing you to fully leverage the advantages of your cloud infrastructure.
              </AccordionDetails>
            </Accordion >
            <Accordion  align='left' variant='standard' sx={{backgroundColor: theme.palette.primary.main,outline: `1px solid ${theme.palette.primary.lightest}`, borderBottom: `1px solid ${theme.palette.primary.lightest}`}}>
              <AccordionSummary
                sx={{flexDirection: 'row-reverse'}}
                expandIcon={<ExpandMore  color="secondary"/>}
                aria-controls="panel23-content"
                id="panel23-header"
              >
               <Typography component={"div"}color={theme => theme.palette.primary.lightest} variant="h6" fontWeight={700}  marginLeft={2}>I use an MSP (Managed Service Provider) -- Why do I need to hire a cybersecurity consultant?</Typography> 
              </AccordionSummary>
              <AccordionDetails sx={{color: theme => theme.palette.primary.lightest}}>
              Depending solely on an MSP for cybersecurity may leave gaps in your defense. Many subscription tiers do not offer robust security 
              provisioning or monitoring, and as one of many clients, you might not receive the tailored attention your business needs. Additionally, 
              MSPs typically don't conduct physical infrastructure visits, potentially overlooking underlying issues. By bringing in a cybersecurity 
              consultant, you ensure tailored strategies, proactive monitoring, and on-site assessments to address specific security needs and uncover 
              hidden vulnerabilities, providing comprehensive protection for your business.
              </AccordionDetails>
            </Accordion >
            <Accordion  align='left' variant='standard' sx={{backgroundColor: theme.palette.primary.main,outline: `1px solid ${theme.palette.primary.lightest}`, borderBottom: `1px solid ${theme.palette.primary.lightest}`}}>
              <AccordionSummary
                sx={{flexDirection: 'row-reverse'}}
                expandIcon={<ExpandMore  color="secondary"/>}
                aria-controls="panel12-content"
                id="panel12-header"
              >
               <Typography component={"div"}color={theme => theme.palette.primary.lightest} variant="h6" fontWeight={700}  marginLeft={2}>Does ASA only offer cybersecurity services?</Typography> 
              </AccordionSummary>
              <AccordionDetails sx={{color: theme => theme.palette.primary.lightest}}>
              While we are primarily a cybersecurity company, we can assist with a myriad of IT concerns. Whether you're experiencing issues with a slow network that's hindering productivity, 
              looking to enhance your IT capabilities to support business growth, or considering upgrades to optimize your current systems, ASA is well-equipped to provide expert solutions 
              tailored to your specific needs.

              </AccordionDetails>
            </Accordion >
            <br></br><br></br>
          {/* </Container> */}
          </Grid>
          </Grid>
          </ScrollAnimation>
        </Container>

        <Container maxWidth="false" sx={{background: theme.palette.primary.gradient, padding: {
          xs: '25px', // Padding for extra small screens
          sm: '15px', // Padding for small screens
          md: '20px', // Padding for medium screens
          lg: '80px'}}}  align="center"  >
          <ScrollAnimation animateOnce animateIn="slideInRight">
          <Container  maxWidth="xl" style={{height: "auto"}}  align="center"  >
            <Grid  container direction="row" justifyContent="center" alignItems="center" spacing={0}>
              <Grid  item lg={6} align="left">
                <Typography component={"div"}variant="h1"   color={'primary'} fontWeight={900}>
                  We bring intelligence and mindset together.
                </Typography><br></br>
                <Typography component={"div"}variant="h3" color={'primary'}  > 
                Transform you cyber security strategy and make it your competitive advantage. Drive cost efficiency and seamlessly build a roadmap. 
                Let's do it right the first time!
                </Typography>
                <br></br>
                <Link to = "/contact" >
                  <Button color="secondary" sx={{width: 260, height: 40, textTransform: 'none', borderRadius: 2 }} variant='contained'>
                    <Typography style={{ marginRight: '10px'}} component={"div"}  color={theme.palette.primary.lightest} fontWeight={600} > Schedule a Consultation</Typography>
                    <East  sx={{color: theme => theme.palette.primary.lightest}} fontSize='medium'  />
                  </Button><br></br><br></br><br></br>
                </Link>
              </Grid>
              <Grid item lg={6}  align="right">
              <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
                  <img src={mindset} alt="alternative_text"></img>
                </Box>
               
              </Grid>
            </Grid>
          </Container>
          </ScrollAnimation>
        </Container>
      <Anchor/>
    </div>
    );
  }