import {Link} from "react-router-dom";
import * as React from 'react';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';

import { 
  Typography, 
  Container, 
  Box, 
  Grid
 } from '@mui/material';
 import { ReactComponent as MyIcon } from './images/asa.svg';
 import icon from './images/asa.png';

export function Anchor() {

    const theme = useTheme()

    return(
        <div>
            <Container maxWidth={false}  style={{backgroundColor: theme.palette.primary.main, bottom: 0, height: "auto"}} sx={{background: 'linear-gradient(90deg, rgba(48, 71, 94, 0.5) 15%, rgba(255, 92, 0, 0.27) 50%, rgba(48, 71, 94)85% )',align: 'center', flexDirection: 'column', alignItems: 'center', gap: '0'}}  >

                <Grid sx={{p:4, overflow: "auto" }} container direction="row"  justifyContent="center" spacing={5}>
                    <Grid  align={'center'}item xs={12} sm={12} md={12} lg={4} xl={4}> 
                        <Box>
                            <MyIcon style={{ width: '70%', height: '14vh' }} />
                        </Box>
                    </Grid>
                    <Grid align={'left'}  item xs={12} sm={4} md={4} lg={3} xl={2}>
                            <Typography component={"div"} color={theme => theme.palette.primary.lightest} fontWeight={900}>
                                Services
                                <Divider style={{backgroundColor: theme.palette.secondary.main, width: '75px', height: '1px' }} />
                            </Typography>    

                            <Link style={{ textDecoration: 'none' }} to = "/assessments" >
                                <Typography component={"div"}sx={{ textDecoration: 'none', transition: 'text-decoration 0.3s', '&:hover': { textDecoration: 'underline' } }} 
                                color={theme => theme.palette.primary.lighter}>Vulnerability Assessments</Typography>
                            </Link>
                        <div>   
                            <Link style={{ textDecoration: 'none' }} to = "/endpoint" >
                                <Typography component={"div"}sx={{ textDecoration: 'none', transition: 'text-decoration 0.3s', '&:hover': { textDecoration: 'underline' } }} 
                                color={theme => theme.palette.primary.lighter}  variant="body">Endpoint Security
                                </Typography>
                            </Link>
                        </div>
                        <div>   
                            <Link style={{ textDecoration: 'none' }} to = "/compliance" >
                                <Typography component={"div"}sx={{ textDecoration: 'none', transition: 'text-decoration 0.3s', '&:hover': { textDecoration: 'underline' } }} 
                                color={theme => theme.palette.primary.lighter}  variant="body">Compliance
                                </Typography>
                            </Link>
                        </div>
                        <div>   
                            <Link style={{ textDecoration: 'none' }} to = "/networksecurity" >
                                <Typography component={"div"}sx={{ textDecoration: 'none', transition: 'text-decoration 0.3s', '&:hover': { textDecoration: 'underline' } }} 
                                color={theme => theme.palette.primary.lighter}  variant="body">Wireless Network Security Assessments
                                </Typography>
                            </Link>
                        </div>
                        <div>
                            
                            <Link style={{ textDecoration: 'none' }} to = "/training" >
                                <Typography component={"div"}sx={{ textDecoration: 'none', transition: 'text-decoration 0.3s', '&:hover': { textDecoration: 'underline' } }} 
                                color={theme => theme.palette.primary.lighter}  variant="body">Training and Awareness
                                </Typography>
                            </Link>
                        </div>
                        <div>
                            
                            <Link style={{ textDecoration: 'none' }} to = "/continuity" >
                                <Typography component={"div"}sx={{ textDecoration: 'none', transition: 'text-decoration 0.3s', '&:hover': { textDecoration: 'underline' } }} 
                                color={theme => theme.palette.primary.lighter}  variant="body">Incident Response Planning
                                </Typography>
                            </Link>
                        </div>
                        <div>
                            
                            <Link style={{ textDecoration: 'none' }} to = "/crq" >
                                <Typography component={"div"}sx={{ textDecoration: 'none', transition: 'text-decoration 0.3s', '&:hover': { textDecoration: 'underline' } }} 
                                color={theme => theme.palette.primary.lighter}  variant="body"> Risk Quantification
                                </Typography>
                            </Link>
                        </div>
                        
                    </Grid>

                    <Grid align={'left'}  item xs={12} sm={4} md={4} lg={3} xl={2}>
                        <Typography component={"div"} color={theme => theme.palette.primary.lightest} fontWeight={900}>
                            Quick Links
                            <Divider style={{backgroundColor: theme.palette.secondary.main, width: '75px', height: '1px' }} />
                        </Typography>   
                        <div>
                        <Link style={{ textDecoration: 'none' }} to = "/asa" >
                            <Typography component={"div"}sx={{ textDecoration: 'none', transition: 'text-decoration 0.3s', 
                            '&:hover': { textDecoration: 'underline' } }} color={theme => theme.palette.primary.lighter}  variant="body">About Us
                            </Typography>
                        </Link>
                        </div>
                        <div>
                        <Link style={{ textDecoration: 'none' }}  to = "/whyasa" >
                            <Typography component={"div"}sx={{ textDecoration: 'none', transition: 'text-decoration 0.3s', 
                            '&:hover': { textDecoration: 'underline' } }} color={theme => theme.palette.primary.lighter}  variant="body">Why ASA
                            </Typography>
                        </Link>
                        </div>
                        <div>
                            <Link style={{ textDecoration: 'none' }} to = "/contact" >
                                <Typography component={"div"}sx={{ textDecoration: 'none', transition: 'text-decoration 0.3s', 
                                '&:hover': { textDecoration: 'underline' } }} color={theme => theme.palette.primary.lighter}  variant="body">Contact
                                </Typography>
                            </Link>
                        </div>
                        <Typography component={"div"} sx={{ textDecoration: 'none', transition: 'text-decoration 0.3s', 
                        '&:hover': { textDecoration: 'underline' } }} color={theme => theme.palette.primary.lighter}>
                            <a
                            href={'mailto: info@asacyber.com'}
                            style={{ textDecoration: 'none', color: theme.palette.primary.lighter }}
                            >
                              Email: info@asacyber.com
                            </a>
                        </Typography>  
                        <Typography component={"div"} sx={{ textDecoration: 'none', transition: 'text-decoration 0.3s', 
                        '&:hover': { textDecoration: 'underline' } }} color={theme => theme.palette.primary.lighter}>
                            <a
                            href={'tel: 402-403-7055'}
                            style={{ textDecoration: 'none', color: theme.palette.primary.lighter }}
                            >
                                Telephone: 402-403-7055
                            </a>
                        </Typography>   
                    </Grid>        
                </Grid>
            </Container>

            <Container
                maxWidth={false}
                style={{ bottom: 0, backgroundColor: theme.palette.primary.main, height: "auto", display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '1rem' }}
                sx={{ p: 2 }}
                align="center"
            >
                <img style={{width: '30px', height: "30px"}} src={icon} alt="" ></img>
   
                <Typography component={"div"}color={theme.palette.primary.lightest} fontWeight={600}>
                    Copyright © 2024 ASA Cybersecurity. All Rights Reserved.
                </Typography>
            </Container>
        </div>
    )
}