import React, {useState, useEffect } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"
import { Anchor } from "./Anchor";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Divider from '@mui/material/Divider';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './slick-overrides.css';
import { 
  Typography, 
  Container, 
  Box,
  Paper,
  Grid
 } from '@mui/material';
import wallpaper from './images/asabg.png'
import { useTheme } from '@mui/material/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import uvp from './images/uvp.png'
import linux from './images/linux.svg'
import pfsense from './images/pfsense.png'
import clamav from './images/clamav.svg'
import onion from './images/onion.svg'
import { 
  ListItem, 
  ListItemIcon, 
 } from '@mui/material';
import cyber4 from './images/cyber4.png'


function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export function WhyAsa() {

  const [isExpanded, setIsExpanded] = useState();
  const theme = useTheme()

  useEffect(()=>{
    window.scrollTo(0, 0);
    setIsExpanded(true)
  },[])

  const itemData = [
    {
      img: linux,
      title: 'Breakfast',
      rows: 2,
      cols: 2,
    },
    {
      img: onion,
      title: 'Burger',
      cols: 2,
    },
    {
      img: clamav,
      title: 'Camera',
      cols: 2,
    },
    {
      img: pfsense,
      title: 'Hats',
      cols: 4,
    },
  
  ];

  return (
    <div>
      
      <Container maxWidth={false} style={{
        backgroundImage: `url(${wallpaper})`,
        backgroundSize: 'cover',
        alignItems: 'center',
        justifyContent: 'center', // Center horizontally
        flexDirection: 'column', 
        minHeight: "400px", display: 'flex'}} align="center"  >
        <Container  maxWidth='md' sx={{borderRadius: 2, p: 3}}>
        {isExpanded?
          <ScrollAnimation initiallyVisible animateOnce animateIn="backInLeft">
            <Typography component={"div"} align='left' variant='title' color={theme.palette.primary.lightest}  fontWeight='900' > 
              Our Unique Value Proposition
            </Typography>      
          </ScrollAnimation>
          :""}
        </Container>
      </Container>

      
        <Container align="left" sx={{ padding: {
          xs: '20px', // Padding for extra small screens
          sm: '15px', // Padding for small screens
          md: '20px', // Padding for medium screens
          lg: '60px'} }}>
          <Typography component={"div"} variant='h1'color={theme.palette.primary.main} sx={{p:1}} fontWeight='900' > 
          Expertise of a Big Firm, Agility of a Small Firm
          </Typography>
          <Divider style={{backgroundColor: theme.palette.secondary.main, width: '200px', height: '2px' }} />   
          <br></br>
          <br></br>

          <Grid container direction="row-reverse" justifyContent="center" alignItems="top" spacing={10}>
            <Grid item md={6} >
            <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
            {isExpanded ?
              <ScrollAnimation animateOnce animateIn="bounceInRight">
                <img style={{ width: '400px', height: '400px' }} src={uvp} alt="alternative_text"></img>
              </ScrollAnimation> :""}
            </Box>
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
              <ScrollAnimation animateOnce animateIn="bounceInRight">
                <img style={{ width: '350px', height: '350px' }}src={uvp} alt="alternative_text"></img>
              </ScrollAnimation>
            </Box>
            
            </Grid>
            <Grid  item md={6}>
              <Typography component={"div"}textAlign={'left'} >
                At ASA, we combine the skills, education, experience, and expertise typically found in large organizations, with 
                the agility and personalized service of a 
                smaller firm. Because we don't have the overhead costs that larger organizations do, we are able to deliver 
                the value of a larger organization, but at a price point that small businesses can afford. 
                <br></br><br></br>
                Our team is 
                composed of seasoned professionals who have honed their 
                craft through years of academic education, practical experience, and continuous learning in the rapidly evolving field 
                of cybersecurity. This allows us to provide tailored solutions that meet the unique needs of each 
                client efficiently and effectively. By focusing 100% of our resources on each customer, we ensure 
                that every dollar our clients invest in our services goes towards tangible results and peace of mind.
              </Typography>
            </Grid>
          </Grid>
        </Container>

    
      <Container maxWidth={'lg'} align="center" >
        <Grid  container direction="row" justifyContent="center" alignItems="top" spacing={5}>
          <Grid item lg={4} align="center">
          <ScrollAnimation duration={2} animateOnce animateIn="slideInLeft">
            <Paper  elevation={5} sx={{background: `url(${wallpaper})`, backgroundPosition: 'center', backgroundSize: 'cover',width: {xs: '350px'}, 
            borderRadius: 7, p: 5}}  
              style={{ overflow: 'auto', wordWrap: 'break-word' }}>
              <Typography component={"div"} color={theme.palette.primary.lightest} sx={{p:1}} fontWeight={700} variant='h2'>Agility</Typography>
              <Divider style={{backgroundColor: theme.palette.secondary.main, width: '75px', height: '2px' }} />
              <br></br>
              <Typography component={"div"} color={theme.palette.primary.lightest} sx={{p:1}}>
              ASA offers personalized service and nimble responsiveness. Unlike larger firms 
              burdened by overhead costs, our agile approach allows us to deliver swift, tailored solutions. 
              We prioritize client satisfaction, dedicating our resources to ensuring tangible results and 
              peace of mind.
              </Typography>
            </Paper>
            </ScrollAnimation>
          </Grid>

          <Grid item lg={4} align="center">
          <ScrollAnimation duration={2} animateOnce animateIn="slideInUp">
            <Paper  elevation={5} sx={{background: `url(${wallpaper})`, backgroundPosition: 'center', backgroundSize: 'cover',width: {xs: '350px'}, borderRadius: 7, p: 5}}  
              style={{ overflow: 'auto', wordWrap: 'break-word' }}>
              <Typography component={"div"} color={theme.palette.primary.lightest} sx={{p:1}} fontWeight={700} variant='h2'>Expertise</Typography>
              <Divider style={{backgroundColor: theme.palette.secondary.main, width: '75px', height: '2px' }} />
              <br></br>
              <Typography component={"div"} color={theme.palette.primary.lightest} sx={{p:1}}>
              We leverage extensive skills, education, and experience synonymous with large organizations. 
              Our seasoned cybersecurity professionals bring years of academic rigor and practical expertise, 
              ensuring tailored solutions that meet diverse client needs with precision.
              </Typography>
            </Paper>
            </ScrollAnimation>
          </Grid>
          
          <Grid  item lg={4} align="center">
          <ScrollAnimation duration={2} animateOnce animateIn="slideInRight">
            <Paper  elevation={5} sx={{background: `url(${wallpaper})`, backgroundPosition: 'center', backgroundSize: 'cover',width: {xs: '350px'}, borderRadius: 7, p: 5}}  
              style={{ overflow: 'auto', wordWrap: 'break-word' }}>
              <Typography component={"div"} color={theme.palette.primary.lightest} sx={{p:1}} fontWeight={700} variant='h2'>Affordability</Typography>
              <Divider style={{backgroundColor: theme.palette.secondary.main, width: '75px', height: '2px' }} />
              <br></br>
              <Typography component={"div"} color={theme.palette.primary.lightest} sx={{p:1}}>
              By exclusively working with small businesses, we are able to reduce our dependence 
              on certain software tools, technologies, and licenses that aren't needed for small businesses (e.g. Enterprise-level software licenses).
              By streamlining operations and minimizing costs, we deliver top-tier cybersecurity solutions 
              without compromising affordability. 
              </Typography>
            </Paper>
            </ScrollAnimation>
          </Grid>
        </Grid>
        <br></br><br></br><br></br><br></br>
      </Container>

      {/* <Container maxWidth={false} style={{height: "auto"}} sx={{padding: {
          xs: '15px', // Padding for extra small screens
          lg: '70px'} }} >
        <Grid  container direction="row" justifyContent="center" alignItems="center" spacing={1}>
          <Grid item md={6}   align="left">
          <Typography component={"div"}variant="h1"  color={'primary'} fontWeight={800}>
              Custom, In-Person Solutions. Always.
          </Typography><br></br>
    
                <ListItem sx={{alignItems: 'flex-start'}}>
                  <ListItemIcon >
                    <CheckCircleOutlineIcon style={{ fontSize: '30px' }} color="secondary"  />
                  </ListItemIcon>
                  <div>
                    <Typography component={"div"}color='primary' variant="h3" fontWeight={900}>1. Risk Mitigation
                    </Typography>
                    <Typography component={"div"}color='primary' variant="body">Identifying vulnerabilities allows organizations 
                      to prioritize and address potential risks, minimizing the likelihood of security breaches.
                    </Typography>
                  </div>
                </ListItem>

                <ListItem sx={{alignItems: 'flex-start'}}>
                  <ListItemIcon >
                    <CheckCircleOutlineIcon style={{ fontSize: '30px' }} color="secondary"  />
                  </ListItemIcon>
                  <div>
                    <Typography component={"div"}color='primary' variant="h3" fontWeight={900}>2. Regulatory Compliance</Typography>
                    <Typography component={"div"}color='primary' variant="body">Many industries have stringent compliance requirements. 
                    Conducting regular vulnerability assessments helps organizations comply with regulations and avoid legal consequences.
                    </Typography>
                  </div>
                </ListItem>


                <ListItem sx={{alignItems: 'flex-start'}}>
                  <ListItemIcon >
                    <CheckCircleOutlineIcon style={{ fontSize: '30px' }} color="secondary"  />
                  </ListItemIcon>
                  <div>
                    <Typography component={"div"}color='primary' variant="h3" fontWeight={900}>3. Proactive Security
                    </Typography>
                    <Typography component={"div"}color='primary' variant="body">A proactive approach to security involves identifying and 
                    addressing vulnerabilities before they can be exploited, minimizing the impact of potential attacks.
                    </Typography>
                  </div>
                </ListItem>

                <ListItem sx={{alignItems: 'flex-start'}}>
                  <ListItemIcon >
                    <CheckCircleOutlineIcon style={{ fontSize: '30px' }} color="secondary"  />
                  </ListItemIcon>
                  <div>
                  <Typography component={"div"}color='primary' variant="h3" fontWeight={900}>4. Cost Savings</Typography>
                  <Typography component={"div"}color='primary' variant="body">
                  Save costs by proactively identifying and addressing security risks, 
                  preventing potential breaches and associated financial losses.  
                  </Typography>
                  </div>
                </ListItem>

            <br></br>
          </Grid>
          <Grid item md={4}>
            <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
                <img style={{ width: '600px', height: '500px' }} src={cyber4} alt="alternative_text"></img>
              </Box>
              <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <img style={{ width: '350px', height: '290px' }}src={cyber4} alt="alternative_text"></img>
              </Box>
          </Grid>
        </Grid>
      </Container> */}


      <Container maxWidth={false} sx={{background: theme.palette.primary.gradient, padding: {
        xs: '15px', // Padding for extra small screens
        lg: '50px'}}}>
        <Container align="left" sx={{padding: {
          xs: '20px', // Padding for extra small screens
          sm: '15px', // Padding for small screens
          md: '20px', // Padding for medium screens
          lg: '50px'} }}>
          
          <Typography component={"div"} variant='h1'color={theme.palette.primary.main} sx={{p:1}} fontWeight='900' > 
            Cost Savings With Open-Source Solutions
          </Typography>
        
          <br></br>
          <br></br>
          <Grid container direction="row-reverse" justifyContent="center" alignItems="top" spacing={10}>
            <Grid item md={6} >
              <Typography variant={'h2'} color={'secondary'} component={"div"}textAlign={'left'} >
                What is Open-Source Software?
                </Typography>
                <Typography component={"div"}textAlign={'left'} >
                Open-source software is freely available for anyone to use, modify, and distribute. For small businesses, it offers robust security 
                solutions at low or no cost. The open nature allows developers worldwide to collaborate on identifying and fixing 
                vulnerabilities quickly, ensuring faster updates and patches compared to proprietary 
                software. By adopting open-source solutions, businesses can strengthen their security defenses effectively and affordably.
                </Typography>
                <br></br><br></br>
                <Typography variant={'h2'} color={'secondary'} component={"div"}textAlign={'left'} >
                How Does ASA Use Open-Source?
                </Typography>
                <Typography component={"div"}textAlign={'left'} >
                  Our #1 priority is to help our customers create a secure IT infrastructure and gain or maintain regulatory compliance at the lowest cost possible.
                  Between recent high inflation and reduced operating margins, we understand that small businesses have tight budgets, and especially so when it comes to cybersecurity. 
                  That's why ASA's promise is to always provide free software solutions to security and/or compliance gaps. From antivirus and EDR, to SIEM and Firewalls, there are very 
                  few use cases where open-source software can't fill the requirement. Totally free of cost to you. 
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
                  <ScrollAnimation duration={2} animateOnce animateIn="bounceInLeft">
                    <ImageList
                        sx={{ width: 500, height: 450 }}
                        variant="quilted"
                        cols={4}
                        rowHeight={121}
                      >
                        {itemData.map((item) => (
                          <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                            <img
                              {...srcset(item.img, 121, item.rows, item.cols)}
                              alt={item.title}
                              loading="lazy"
                            />
                          </ImageListItem>
                        ))}
                      </ImageList>
                    </ScrollAnimation>
                  </Box>
              </Grid>
            </Grid>
        </Container>
      </Container>

     
      <Container maxWidth={false} sx={{ padding: {
          xs: '15px', // Padding for extra small screens
          lg: '50px'}}}>
        
        <Grid  container direction="row" justifyContent="center" alignItems="center" spacing={5}>
          <Grid item md={10} align="left">
          <ScrollAnimation animateOnce animateIn="fadeIn">
            <Typography cmponent={"div"} variant="h1" color={'primary'} fontWeight={800}>
              In Short:
            </Typography>
            <Divider style={{backgroundColor: theme.palette.secondary.main, width: '100px', height: '2px' }} /> 
            <br></br>
            <Typography component={"div"}  sx={{color: theme => theme.palette.primary.main}}  > 
            ASA is committed to offering industry standard solutions at the lowest possible price. By avoiding the overhead costs 
            of larger entities, we deliver the value of 
            extensive expertise at a price point accessible to small businesses. This enables us to offer customized 
            solutions that efficiently meet each client's unique needs. 
            By focusing all our resources on each customer, we can guarantee that every investment
            in our services delivers tangible results for your business.
            </Typography>
            <br></br><br></br><br></br>
            </ScrollAnimation>
          </Grid>
        </Grid>
      </Container>
    <div>
    <Anchor/>
    </div>
    </div>
  );
}
